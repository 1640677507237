<template>
  <v-list-item :to="item.url" data-testid="meeting-document-list-item">
    <v-list-item-avatar class="rounded-0">
      <v-icon>{{ `${item.mimeTypeIcon}` }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="text-wrap d-flex align-center">
        {{ item.title }}
        <v-icon
          v-if="item.color_code"
          class="ml-2"
          :size="12"
          :data-testid="`document_designation-icon-${item.title}`"
          :color="item.color_code"
        >
          mdi-square-rounded
        </v-icon>
      </v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        {{ $t('last_update_on', { date: toLocaleDateTimeString(item.updated) }) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <ListActionIndicator :item="item" />
  </v-list-item>
</template>

<script>
import mixin from '@/components/listitems/mixin'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'MeetingDocumentListItem',
  mixins: [mixin],
  methods: { toLocaleDateTimeString },
}
</script>
