<template>
  <v-tab v-bind="$attrs" class="blue-grey lighten-5" :ripple="false">
    <div>
      <span class="text-subtitle-2">{{ title }}</span>
      <span class="caption ml-1">({{ count }})</span>
    </div>
  </v-tab>
</template>

<script>
export default {
  name: 'SearchTab',
  inheritAttrs: false,
  props: {
    count: {
      type: Number,
      default: () => 0,
    },
    title: {
      type: String,
      default: () => '',
    },
  },
}
</script>
