import axios from 'axios'
import joinPath from 'path.join'

export const api = axios.create({
  baseURL: window.location.pathname,
  headers: {
    Accept: 'application/json',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

export function setBaseURL(baseURL) {
  api.defaults.baseURL = baseURL
}

export const docInstance = axios.create({
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/pdf',
  },
})

// Because the django backend likes trailing slashed
// make sure to append at every request
api.interceptors.request.use((request) => {
  const { url } = request
  // eslint-disable-next-line no-param-reassign
  request.url = url.slice(-1) === '/' ? url : `${url}/`
  return request
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error
    if (response.status === 401) {
      const nextURL = window.location.href
      const nextURLQuery = new URLSearchParams({ next: nextURL })

      const loginURL = new URL(window.location.origin)
      loginURL.pathname = joinPath(api.defaults.baseURL, 'auth/login/')
      loginURL.search = nextURLQuery.toString()

      window.location = loginURL
    }
    return Promise.reject(error)
  },
)

export function fetchDocument(url) {
  return docInstance.get(url, { withCredentials: true })
}
