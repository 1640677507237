<template>
  <LinkedListItem :item="item">
    <v-list-item-title class="pl-3 py-2 text-wrap">{{ item.title }}</v-list-item-title>
  </LinkedListItem>
</template>

<script>
import mixin from '@/components/listitems/mixin'
import { toLocaleDateString } from '@/filters'

export default {
  name: 'CommitteeListItem',
  mixins: [mixin],
  methods: {
    toLocaleDateString,
  },
}
</script>
