<template>
  <v-list-item ripple>
    <v-list-item-content>
      <v-list-item-title>
        {{ item.displayName }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <a :href="item.mailToLink" target="_blank">
          {{ item.email }}
        </a>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import mixin from '@/components/listitems/mixin'

export default {
  name: 'UserListItem',
  mixins: [mixin],
}
</script>
