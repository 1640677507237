import i18n from '@/i18n'
import { fragFormat } from './frag-format'

const fragments = {
  meeting: [
    {
      name: i18n.t('Fragment_label_documents_available'),
      text: fragFormat(i18n.t('Fragment_meeting_notification_start') + i18n.t('Fragment_documents_available')),
    },
    {
      name: i18n.t('Fragment_label_meeting_updated'),
      text: fragFormat(i18n.t('Fragment_meeting_notification_start') + i18n.t('Fragment_meeting_updated')),
    },
    {
      name: i18n.t('Fragment_label_meeting_postponed'),
      text: fragFormat(i18n.t('Fragment_meeting_notification_start') + i18n.t('Fragment_meeting_postponed')),
    },
  ],
}

export default fragments
