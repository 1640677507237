export function localeSort(arr = [], sortKey = null, options = {}) {
  const lang = window.navigator.language

  if (sortKey) {
    arr.sort((x, y) => x[sortKey].localeCompare(y[sortKey], lang, options))
  } else {
    arr.sort((x, y) => x.localeCompare(y, lang, options))
  }
  return arr
}
