<template>
  <BaseLayout narrow fill-container-height>
    <CommitteesSidebar slot="drawer-left" />
    <v-container fluid class="not-found-background fill-height">
      <div class="d-flex align-center justify-center flex-column grow">
        <h1 class="white--text mb-4 display-3">{{ $t('page_not_found') }}</h1>
        <v-btn outlined color="white" :to="{ name: 'root' }">
          {{ $t('back_to_start_page') }}
        </v-btn>
      </div>
    </v-container>
  </BaseLayout>
</template>

<script>
import Committee from '@/store/models/committee'

export default {
  name: 'NotFound',
  async initialAsyncData() {
    await Committee.fetchAll()
  },
}
</script>
<style lang="scss" scoped>
.not-found-background {
  background-image: linear-gradient(-45deg, #dadada 0%, #5e5e5e 100%);
  background-size: 400% 400%;
  animation: rotate-gradient 10s ease infinite;
}

@keyframes rotate-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
