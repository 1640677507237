<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  head: {
    script() {
      if (this.$config.tracking_url) {
        return [
          {
            type: 'text/javascript',
            src: this.$config.tracking_url,
            async: true,
          },
        ]
      }
    },
  },
}
</script>

<style lang="scss">
/* Override list item styles to prevent text from being clamped. */
.v-list__tile {
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-list__tile,
.v-list__tile__title {
  height: auto !important;
}

.v-list__tile__title {
  white-space: normal !important;
}
.v-data-table-header th {
  white-space: nowrap;
}
</style>
