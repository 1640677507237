<template>
  <NotificationDialog
    :fragments="fragments"
    :fragment-context="fragmentContext"
    :fragment-boxes="fragmentBoxes"
    :url="notificationURL"
    :principals="principals"
  >
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps" />
    </template>
    <p slot="description" class="caption">
      {{ $t('notification_description') }}
    </p>
  </NotificationDialog>
</template>

<script>
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import { toLocaleDateTimeString } from '@/filters'
import fragments from '@/text-fragments'
import { fragFormat } from '../../frag-format'

const notificationURLTemplate = '/committees/{committee}/meetings/{meeting}/notification/'

export default {
  name: 'MeetingNotificationDialog',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fragments: fragments.meeting,
      principals: [],
    }
  },
  computed: {
    fragmentContext() {
      return {
        committee: this.meeting.committee.title,
        date: toLocaleDateTimeString(this.meeting.start),
        meeting: this.meeting.title,
      }
    },
    fragmentBoxes() {
      return [
        {
          label: this.$t('Add_date'),
          content: toLocaleDateTimeString(this.meeting.start),
        },
      ]
    },
    notificationURL() {
      return createLink(notificationURLTemplate, {
        committee: this.meeting.committee_id,
        meeting: this.meeting.id,
      })
    },
  },
  async created() {
    await this.fetchMeetingTemplateFragments()
    this.principals = await this.meeting.committee.fetchPrincipals()
  },
  methods: {
    toLocaleDateTimeString,
    async fetchMeetingTemplateFragments() {
      const meetingNotificationFragmentURL = createLink('/meeting_template_fragments')
      const request = await api.get(meetingNotificationFragmentURL)

      if (request.data.length) {
        this.fragments = []
        request.data.forEach((entry) => {
          this.fragments.push({
            name: entry.name,
            text: fragFormat(entry.text),
          })
        })
      }
    },
  },
}
</script>
