export default function download(url, filename) {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.style = 'display: none'
  if (filename) {
    link.download = filename
  }
  link.href = url
  link.target = 'blank'
  link.click()
  document.body.removeChild(link)
}
