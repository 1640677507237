<template>
  <Sidebar>
    <DividedSidebarList
      data-testid="meeting-documents"
      :list-item="MeetingDocumentListItem"
      :items="meetingDocuments"
      title="Zurück zur Sitzung"
      :subtitle="meetingDocument.meeting.title"
      :parent="meetingDocument.meeting"
    />
  </Sidebar>
</template>

<script>
import MeetingDocument from '@/store/models/meetingdocument'
import MeetingDocumentListItem from '@/components/listitems/MeetingDocumentListItem'
import mixin from '@/components/sidebars/mixin'
import { localeSort } from '@/sorters'

export default {
  name: 'MeetingDocumentSidebar',
  mixins: [mixin],
  props: {
    meetingDocument: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      MeetingDocumentListItem,
    }
  },
  computed: {
    meetingDocuments() {
      const meetingDocuments = MeetingDocument.query().where('meeting_id', this.meetingDocument.meeting_id).all()
      return localeSort(this.filterLatestVersions(meetingDocuments), 'title')
    },
  },
}
</script>
