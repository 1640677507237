<template>
  <v-list-item v-bind="link">
    <v-list-item-avatar v-if="item.icon" class="rounded-0">
      <v-icon>{{ `${item.icon}` }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="text-wrap">{{ item.title }}</v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        {{ $t('updated_last_on', { date: toLocaleDateTimeString(item.updated) }) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="deleteable">
      <v-btn icon @click.stop.prevent="destroyItem(item)">
        <v-icon color="error"> mdi-delete </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import mixin from '@/components/listitems/mixin'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'DocumentListItem',
  mixins: [mixin],
  props: {
    deleteable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    link() {
      if (this.item.USE_PSPDFKIT) {
        // construct a route to a page that uses PSPDFKit viewer
        return { to: this.item.url }
      }
      // otherwise serve the file to be displayed using the browser's PDF viewer
      return {
        href: this.item.file,
        target: '_blank',
      }
    },
  },
  methods: {
    toLocaleDateTimeString,
    destroyItem(item) {
      const confirm = window.confirm(this.$t('document_delete_confirmation'))
      if (confirm) {
        this.$emit('destroy-item', { item })
      }
    },
  },
}
</script>
