<template>
  <div
    class="reveal-content-container d-flex"
    data-testid="reveal-content-container"
    :class="{ revealable: canReveal }"
  >
    <div class="reveal-content-text mb-0" :style="{ 'max-height': maxHeight }">
      <p ref="reveal-text" class="mb-0" v-html="linkifiedDescription" />
      <div v-if="canReveal && !revealed" class="reveal-content-drop" />
    </div>
    <v-btn
      v-if="canReveal"
      data-testid="reveal-button"
      small
      depressed
      text
      class="ml-0 reveal-content-link"
      @click="toggleReveal"
    >
      <template v-if="revealed">
        {{ $t('hide_text') }}
      </template>
      <template v-else>
        {{ $t('reveal_text') }}
      </template>
    </v-btn>
  </div>
</template>

<script>
import linkify from 'linkify-string'

const MAX_REVEAL_HEIGHT = 100

export default {
  name: 'RevealContent',
  props: {
    value: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      revealed: false,
      elementHeight: 0,
    }
  },
  computed: {
    canReveal() {
      return this.elementHeight > MAX_REVEAL_HEIGHT
    },
    linkifiedDescription() {
      return linkify(this.value)
    },
    maxHeight() {
      return this.revealed ? 'none' : `${MAX_REVEAL_HEIGHT}px`
    },
  },
  mounted() {
    this.elementHeight = this.$refs['reveal-text'].clientHeight
  },
  methods: {
    toggleReveal() {
      this.revealed = !this.revealed
    },
  },
}
</script>
<style lang="scss">
.reveal-content-container {
  flex-direction: column;
  width: 100%;
}

.reveal-content-text {
  position: relative;
  overflow: hidden;

  > p {
    white-space: pre-line;
  }
}

.reveal-content-drop {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  margin-top: -10px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%);
}

.reveal-content-link {
  max-width: 150px;
}
</style>
