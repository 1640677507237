export default {
  computed: {
    emptyMessageText() {
      if (this.emptyMessage !== null) {
        return this.emptyMessage
      }
      return this.$t('no_data')
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    listItem: {
      type: Object,
    },
    emptyMessage: {
      default: () => null,
    },
  },
}
