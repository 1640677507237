<template>
  <div>
    <v-list class="py-0" dense data-testid="committee-link-list">
      <p v-if="links.length === 0" class="mb-0 mt-2 text-xs-center grey--text" v-text="$t('committee_links_no_data')" />
      <template v-else>
        <template v-for="(link, index) in links">
          <v-list-item :key="index" :href="link.url" target="_blank">
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center text-wrap">
                <v-icon small class="mr-2" v-text="'mdi-open-in-new'" />
                <a class="text-underline text-truncate" v-text="link.title" />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="committee.is_responsible" class="my-0">
              <v-btn icon :data-testid="`delete-committee-link-${index}`" @click.stop.prevent="deleteLink(link)">
                <v-icon color="error" v-text="'mdi-delete'" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < links.length" :key="index" />
        </template>
      </template>
    </v-list>
    <v-btn
      v-if="committee.is_responsible"
      text
      outlined
      color="primary"
      data-testid="add-committee-link"
      class="mt-2"
      @click="addNewCommitteeLink"
      v-text="$t('committee_links_add_link')"
    />
    <CommitteeLinkEditDialog ref="editDialog" :committee="committee" @success="fetchLinks" />
  </div>
</template>

<script>
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

export default {
  name: 'CommitteeLinkList',
  props: {
    committee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      links: [],
    }
  },
  watch: {
    committee: {
      handler() {
        this.fetchLinks()
      },
    },
  },
  created() {
    this.fetchLinks()
  },
  methods: {
    async fetchLinks() {
      try {
        const { data } = await api.get(createLink('committees/{id}/committee_links', { id: this.committee.id }))
        this.links = sortBy(data, 'title')
      } catch (e) {
        this.notifyError(this.$t('committee_links_fetch_error'))
        this.links = []
      }
    },
    async deleteLink(link) {
      const confirm = window.confirm(this.$t('committee_links_delete_confirmation'))
      if (confirm) {
        try {
          await api.delete(
            createLink('committees/{id}/committee_links/{linkId}', { id: this.committee.id, linkId: link.id }),
          )
          this.notifySuccess(this.$t('committee_links_delete_success'))
        } catch (error) {
          if (error.isAxiosError && get(error, 'response.status') === 403) {
            this.notifyError(error.response.data.detail)
          } else {
            this.notifyError(this.$t('committee_links_delete_error'))
          }
        } finally {
          await this.fetchLinks()
        }
      }
    },
    addNewCommitteeLink() {
      this.$refs.editDialog.open()
    },
  },
}
</script>

<style>
.text-underline {
  text-decoration: underline;
}
</style>
