<template>
  <v-row>
    <v-col>
      <div class="subtitle-2">Quick Support</div>
      <div class="caption">
        <p>Supportzeiten: 08.30 - 11.30h, 14.00 - 16.00h</p>
        <p>
          <a href="https://get.teamviewer.com/6zcpxpv" target="_blank">https://get.teamviewer.com/6zcpxpv</a>
        </p>
        <p class="ma-0">
          <a href="tel:+41582291010">058 229 10 10</a>
        </p>
        <p class="ma-0">
          <a href="mailto:support@support@sg.ch">support@sg.ch</a>
        </p>
      </div>
    </v-col>
    <v-col>
      <div class="subtitle-2">
        {{ $t('help_dialog_contact') }}
      </div>
      <div class="caption">
        <p class="ma-0">Kanton St.Gallen</p>
        <p class="ma-0">Staatskanzlei</p>
        <p class="ma-0">Informatik und Infrastruktur</p>
        <p class="ma-0">Regierungsgebäude</p>
        <p class="ma-0">9001 St.Gallen</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SupportStGallen',
}
</script>
