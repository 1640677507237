import { getField, updateField } from 'vuex-map-fields'

const state = {
  title: '',
  description: '',
  businessType: null,
  isUrgent: false,
  hasSignatureSheet: false,
  // start Solothurn specific fields
  reason: '',
  second_signature: null,
  third_signature: null,
  rights_holder: '',
  // end Solothurn specific fields
}

const mutations = {
  updateField,
  reset(s) {
    s.title = ''
    s.description = ''
    s.businessType = null
    s.isUrgent = false
    s.hasSignatureSheet = false
    s.reason = ''
    s.second_signature = null
    s.third_signature = null
    s.rights_holder = ''
  },
}

const actions = {
  reset({ commit }) {
    commit('reset')
  },
}

const getters = {
  getField,
}

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
}
