<template>
  <v-list two-line class="py-0">
    <template v-if="!!items">
      <template v-for="(item, index) in items">
        <DeleteableDocumentListItem
          :key="item.listKey"
          :item="item"
          :index="index"
          :count="items.length"
          :deleteable="deleteable"
          @destroy-item="$emit('destroy-item', { item })"
        />
        <v-divider v-if="index + 1 < items.length" :key="index"></v-divider>
      </template>
    </template>
    <p v-if="emptyMessageText && items.length === 0" class="mb-0 mt-2 text-xs-center grey--text">
      {{ emptyMessageText }}
    </p>
  </v-list>
</template>

<script>
import mixin from '@/components/lists/mixin'

export default {
  name: 'DeleteableDocumentList',
  mixins: [mixin],
  props: {
    deleteable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
