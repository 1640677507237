import BaseModel from '@/store/models/base'
import Meeting from '@/store/models/meeting'
import joinPath from 'path.join'
import { createLink } from '@/api/helpers'
import { api } from '@/api'

export default class Committee extends BaseModel {
  static entity = 'committee'

  static apiPath = 'committees'

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(''),
      can_import_meeting: this.boolean(false),
      can_change_documents: this.boolean(false),
      can_post_ris_suggestions: this.boolean(false),
      // (currently unused) whether the committee is 'active' according to it's begin and end dates
      is_active: this.boolean(true),
      // whether the current user has an 'active' Membership on this committee according to its dates
      has_active_membership: this.boolean(false),
      hidden: this.boolean(false),
      group_type_order: this.number(0),
      group_type_title: this.string(''),
      ris_proposal_business_types: this.attr([], (v) => v || []),
      meetings: this.hasMany('meeting', 'committee_id'),
      documents: this.hasMany('document', 'committee_id'),
      members_can_see_all_meetings: this.attr(null),
      is_responsible: this.boolean(null),
    }
  }

  get canPostRisProposals() {
    return this.ris_proposal_business_types.length > 0
  }

  get upcomingMeetings() {
    return Meeting.upcoming.where('committee_id', this.id)
  }

  get pastMeetings() {
    return Meeting.past.where('committee_id', this.id)
  }

  get breadcrumbText() {
    return this.title
  }

  async fetchPrincipals() {
    const { data } = await api.get(createLink(joinPath(this.idPath, 'principals')))
    return data
  }
}
