<template>
  <BaseLayout narrow>
    <Sidebar slot="drawer-left">
      <v-list>
        <BackListItem
          :title="$t('Back_to_agenda_item')"
          :parent="documentVersion.agenda_item"
          :subtitle="documentVersion.agenda_item.title"
        />
      </v-list>
    </Sidebar>
    <template v-slot:toolbar-actions>
      <PdfViewerActions :document="documentVersion" />
    </template>
    <PdfViewer :annotate-on="documentVersion">
      <Download :url="documentVersion.file" />
    </PdfViewer>
    <VersionsSidebar slot="drawer-right" :versionable="documentVersion" />
  </BaseLayout>
</template>

<script>
import PersonalDocument from '@/store/models/personaldocument'
import AgendaItem from '@/store/models/agendaitem'
import documentMixin from '@/components/pages/mixins/document'

export default {
  name: 'PersonalDocument',
  mixins: [documentMixin],
  async asyncData({ id, version_id: versionId }) {
    const personaldocument = await PersonalDocument.fetchVersion(id, versionId)
    const [versions, agendaItem] = await Promise.all([
      personaldocument.fetchVersions(),
      AgendaItem.findOrFetch(personaldocument.agenda_item_id),
    ])
    await Promise.all([...versions.map((v) => v.fetchAnnotationLayers()), PersonalDocument.fetchAllByModel(agendaItem)])
  },
  data() {
    return {
      documentType: 'personaldocument',
    }
  },
  computed: {
    document() {
      return PersonalDocument.query().with('agenda_item').with('annotations')
    },
  },
  updated() {
    this.$emit('updateHead')
  },
  head: {
    title() {
      return { inner: this.document.first().title }
    },
  },
}
</script>
