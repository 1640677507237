<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-form ref="form" v-model="formValid" @submit.prevent="submitShare">
      <v-card>
        <v-card-title>
          <h3 v-if="agendaItem" class="headline">
            {{ $t('share_agenda_item_modal_title', { agendaItem: agendaItem.title }) }}
          </h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <p>{{ $t('share_agenda_item_modal_description') }}</p>
          <h2 class="title">{{ $t('share_subtitle_name') }}</h2>
          <v-text-field v-model="share.name" :rules="[$rules.required]" data-testid="share-layer-name" />
          <h2 class="title mt-4">{{ $t('share_title') }}</h2>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('share_search_label')"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            v-model="selectedUsers"
            :headers="headers"
            :items="share.users"
            :no-data-text="$t('no_data')"
            :no-results-text="$t('no_data')"
            hide-default-footer
            disable-pagination
            show-select
            item-key="title"
            :search="search"
            style="max-height: 320px; overflow-y: scroll"
          >
            <template v-slot:item.type="{ item }">
              <v-icon small>{{ getIconForType(item.type) }}</v-icon>
            </template>
          </v-data-table>
          <h2 class="title mt-4">{{ $t('share_subtitle_message') }}</h2>
          <v-checkbox v-model="sendMessage" :label="$t('share_send_message_label')" primary hide-details></v-checkbox>
          <v-textarea v-show="sendMessage" v-model="messageText" :label="$t('share_message_label')"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text data-testid="share-abort" @click="close">
            {{ $t('share_abort') }}
          </v-btn>
          <v-btn
            :disabled="!isValid"
            color="primary"
            :loading="loading"
            depressed
            data-testid="share-activate"
            type="submit"
          >
            {{ $t('share_apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import path from 'path'
import AgendaItem from '@/store/models/agendaitem'
import { api } from '@/api'

const typeIconMap = {
  user: 'mdi-account',
  group: 'mdi-account-group',
}

export default {
  name: 'ShareAgendaItemDialog',
  data() {
    return {
      formValid: false,
      search: '',
      loading: false,
      dialog: false,
      share: { users: [] },
      selectedUsers: [],
      sendMessage: false,
      messageText: '',
      headers: [
        { text: this.$t('share_column_type'), align: 'middle', value: 'type', width: '1%' },
        { text: this.$t('share_column_title'), align: 'left', value: 'title' },
      ],
    }
  },
  computed: {
    agendaItem() {
      return AgendaItem.find(this.$route.params.id)
    },
    isValid() {
      return !!this.selectedUsers.length && this.formValid
    },
  },
  watch: {
    $route: {
      handler() {
        this.dialog = this.$route.query.share_agenda_item === 'true' || this.$route.query.share_agenda_item === true
        if (this.dialog) {
          this.fetchShare()
        }
      },
      immediate: true,
    },
  },
  methods: {
    getIconForType(type) {
      return typeIconMap[type] || ''
    },
    close() {
      const name = this.$route.name
      const params = this.$route.params
      this.sendMessage = false
      this.messageText = ''
      this.$router.push({ name, params })
      this.$refs.form.resetValidation()
    },
    async fetchShare() {
      const { data } = await api.get(path.join('v1', 'agenda_items', this.$route.params.id.toString(), 'share_info'))
      this.share = data
    },
    async submitShare() {
      this.loading = true
      this.selectedUsers.forEach((user) => {
        user.shared = true
      })
      try {
        await api.post(path.join('v1', 'agenda_items', this.$route.params.id.toString(), 'share'), {
          users: this.selectedUsers,
          name: this.share.name,
          send_message: this.sendMessage,
          text: this.messageText,
        })
        this.notifySuccess(this.$t('share_success_message'))
      } catch (e) {
        this.notifyError(this.$t('share_error_message'))
      } finally {
        this.close()
        this.loading = false
      }
    },
  },
}
</script>
