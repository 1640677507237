<template>
  <v-text-field v-model="internalQuery" v-bind="$attrs" :placeholder="$t('Search')" @keyup.enter="applySearch">
    <template #append>
      <v-btn class="mb-1" color="primary" depressed small @click="applySearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'SearchField',
  inheritAttrs: false,
  props: {
    query: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      internalQuery: this.query,
    }
  },
  watch: {
    query(query) {
      this.internalQuery = query
    },
  },
  methods: {
    applySearch() {
      this.$emit('update:query', this.internalQuery)
    },
  },
}
</script>
