<template>
  <BaseLayout hide-search>
    <Sidebar slot="drawer-left">
      <v-list>
        <BackListItem :parent="{ url: currentFullPath }" :title="$t('backFromSearchTitle')" />
      </v-list>
    </Sidebar>

    <v-card>
      <v-card-title>
        <h3 class="display-1">{{ $t('searchTitle') }}</h3>
      </v-card-title>
      <v-card-text>
        <SearchField :query.sync="filter.query" autofocus />
        <v-tabs v-if="hasTypeStats" v-model="filterType" class="mb-3" slider-color="primary" grow show-arrows>
          <SearchTab :count="totalItems" :title="$t('allResults')" href="#" />
          <SearchTab
            v-for="entry in typeStats"
            :key="entry.type"
            :count="entry.count"
            :href="`#${entry.type}`"
            :title="entry.label"
            nuxt
          />
        </v-tabs>
        <v-layout class="flex-wrap">
          <v-col
            class="order-1 order-md-0"
            cols="12"
            :md="facetComponent ? 6 : 12"
            :lg="facetComponent ? 8 : 12"
            :xl="facetComponent ? 9 : 12"
          >
            <ServersideListing :fetch="search" :filter="filter">
              <template #item="{ item }">
                <v-card flat tile nuxt class="search-result" @click="openSearchResult(item)">
                  <!-- eslint-disable vue/no-v-html -->
                  <v-card-title class="py-2 subtitle-1">
                    <v-icon :size="20" class="search-result-icon blue-grey--text text--lighten-1 mr-2">{{
                      getIconForType(item.type)
                    }}</v-icon>
                    <span v-html="(item.highlighting.title && item.highlighting.title[0]) || item.title" />
                  </v-card-title>
                  <v-card-text v-if="canShowLeadText(item.type)">
                    <div
                      v-for="searchableText in item.highlighting.searchable_text"
                      :key="searchableText"
                      v-html="searchableText"
                    />
                  </v-card-text>
                  <!-- eslint-enable vue/no-v-html -->
                  <v-card-actions v-if="item.breadcrumbs" class="flex-wrap" @click.stop.prevent>
                    <v-btn
                      v-if="item.breadcrumbs.committee"
                      depressed
                      small
                      class="ml-2 mb-2"
                      color="greylight darken-1"
                    >
                      <v-icon left color="primary">mdi-account-group</v-icon>
                      <router-link :to="{ name: 'committee', params: { id: item.breadcrumbs.committee.id } }">
                        {{ item.breadcrumbs.committee.title }}
                      </router-link>
                    </v-btn>
                    <v-btn v-if="item.breadcrumbs.meeting" depressed small class="ml-2 mb-2" color="greylight darken-1">
                      <v-icon left color="primary">mdi-calendar-today</v-icon>
                      <router-link :to="{ name: 'meeting', params: { id: item.breadcrumbs.meeting.id } }">
                        {{ item.breadcrumbs.meeting.title }}
                      </router-link>
                    </v-btn>
                    <v-btn
                      v-if="item.breadcrumbs.agenda_item"
                      depressed
                      small
                      class="ml-2 mb-2"
                      color="greylight darken-1"
                    >
                      <v-icon left color="primary">mdi-folder</v-icon>
                      <router-link :to="{ name: 'agendaitem', params: { id: item.breadcrumbs.agenda_item.id } }">
                        {{ item.breadcrumbs.agenda_item.title }}
                      </router-link>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </ServersideListing>
          </v-col>
          <v-col v-if="facetComponent" class="order-0 order-md-1" cols="12" md="6" lg="4" xl="3">
            <component :is="facetComponent" :facets="facets" :filter.sync="filter" />
          </v-col>
        </v-layout>
      </v-card-text>
    </v-card>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout'
import ServersideListing from '@4tw/ui/lib/components/serverside-data/ServersideListing.vue'
import isEmpty from 'lodash/isEmpty'
import { mapState } from 'vuex'
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import { fromQueryString } from '@4tw/ui/lib/util/query'

const typeIconMap = {
  'backend.meeting': 'mdi-calendar-today',
  'backend.agendaitem': 'mdi-folder',
  'backend.committee': 'mdi-account-group',
  'backend.attachment': 'mdi-file',
  'backend.proposal': 'mdi-file',
  'backend.protocol': 'mdi-file',
  'backend.committeedocument': 'mdi-file',
  'backend.meetingdocument': 'mdi-file',
}

export default {
  name: 'IndexSuche',
  components: {
    BaseLayout,
    ServersideListing,
  },
  data() {
    return {
      facets: {},
      filter: fromQueryString(this.$route.query, ['query']),
      portalTypeStats: {},
      totalItems: 0,
      items: [],
    }
  },
  computed: {
    ...mapState('application', {
      currentFullPath: (state) => {
        const rootUrl = new URL(state.rootUrl)
        return state.currentFullPath || rootUrl.pathname
      },
    }),
    facetComponent() {
      switch (this.filterType) {
        case 'virtual.document':
          return 'DocumentFacets'
        case 'backend.agendaitem':
          return 'AgendaItemFacets'
        case 'backend.meeting':
          return 'MeetingFacets'
        default:
          return null
      }
    },
    filterType: {
      get() {
        return this.filter.type || ''
      },
      set(filterType) {
        if (filterType === this.filterType) return
        this.filter = { query: this.filter.query, type: filterType }
      },
    },
    hasTypeStats() {
      return !!this.typeStats.length
    },
    typeStats() {
      if (isEmpty(this.portalTypeStats)) return []
      return Object.entries(this.portalTypeStats).map(([type, data]) => ({
        type,
        count: data.count,
        label: data.label,
      }))
    },
  },
  methods: {
    getIconForType(type) {
      return typeIconMap[type] || ''
    },
    async search(filter) {
      const response = await api.get(createLink('search'), { params: { ...filter } })
      this.facets = response.data.facets
      this.portalTypeStats = response.data.portal_type_stats
      this.totalItems = response.data.total_items

      return response.data
    },
    isFileType(type) {
      return [
        'backend.attachment',
        'backend.committeedocument',
        'backend.meetingdocument',
        'backend.proposal',
        'backend.protocol',
      ].includes(type)
    },
    canShowLeadText(type) {
      return this.isFileType(type)
    },
    openSearchResult(item) {
      const { type, path } = item || {}
      if (!item) {
        this.items = []
        return
      }
      if (type === 'backend.committeedocument') {
        window.open(path, '_blank')
      } else {
        this.$router.push(item)
      }
    },
  },
}
</script>

<style lang="scss">
.search-result {
  em {
    font-style: normal;
    padding: 1px 4px;
    position: relative;

    &::before {
      background: var(--v-primary-base);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.2;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &:hover {
    background-color: var(--v-greydark-base);
  }
}
</style>
