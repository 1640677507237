<template>
  <v-app class="fill-height">
    <v-main>
      <v-container fluid class="fill-height">
        <div class="d-flex align-center flex-column grow">
          <v-icon class="mb-2" x-large>mdi-link-variant-off</v-icon>
          <p>
            {{ $t('user_not_assigned_to_a_committee', { username: user.username }) }}
          </p>
          <p>{{ $t('please_contact_person_responsible') }}</p>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Profile from '@/store/models/profile'

export default {
  name: 'NoCommittee',
  computed: {
    user() {
      return Profile.query().first()
    },
  },
}
</script>
