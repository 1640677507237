import joinPath from 'path.join'
import { api } from '@/api'
import AnnotationLayer from '../annotation_layer'

export default {
  async fetchAnnotationLayers() {
    const path = joinPath('v1', 'versionable', this.version_id.toString(), 'annotation_layers')
    const { data } = await api.get(path)
    AnnotationLayer.insertOrUpdate({ data })
  },
}
