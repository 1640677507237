import { render, staticRenderFns } from "./DividedList.vue?vue&type=template&id=01235590&"
import script from "./DividedList.vue?vue&type=script&lang=js&"
export * from "./DividedList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports