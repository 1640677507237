<template>
  <v-select
    v-model="note.committee_groups"
    :label="$t('share_note')"
    item-text="title"
    :items="committeeGroups"
    multiple
    return-object
    :persistent-placeholder="note.public"
    :placeholder="public_info"
    :persistent-hint="note.public && !!note.committee_groups.length"
    :hint="public_info"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggle"
        ><v-list-item-action>
          <v-simple-checkbox v-model="note.public" color="primary" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('for_committee') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CommitteeGroupSelect',
  props: {
    note: {
      type: Object,
      required: true,
    },
    committeeGroups: {
      type: Array,
      required: true,
    },
  },
  computed: {
    public_info() {
      return this.note.public ? this.$t('hint_visible_for_committee') : ''
    },
  },
  methods: {
    toggle() {
      this.note.public = !this.note.public
    },
  },
}
</script>
