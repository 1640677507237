<template>
  <v-list-item ripple>
    <v-list-item-content>
      <v-list-item-title v-text="item.title" />
      <v-list-item-subtitle>
        <template v-if="item.members.length || item.email_addresses.length">
          <span v-if="item.members.length" v-text="$tc('committee_group_members_count', item.members.length)" />
          <span v-if="item.members.length && item.email_addresses.length" class="mx-2" v-text="'|'" />
          <span
            v-if="item.email_addresses.length"
            v-text="$tc('committee_group_email_addresses_count', item.email_addresses.length)"
          />
        </template>
        <span v-else class="text--disabled" v-text="$t('committee_group_no_recipients')" />
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import mixin from '@/components/listitems/mixin'

export default {
  name: 'CommitteeGroupListItem',
  mixins: [mixin],
}
</script>
