<template>
  <DividedList :items="users" :empty-message="$t('no_users_existing')" :list-item="UserListItem" />
</template>

<script>
import UserListItem from '@/components/listitems/UserListItem'
import mixin from '@/components/lists/mixin'

export default {
  name: 'UserList',
  mixins: [mixin],
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { UserListItem }
  },
}
</script>
