<template>
  <BaseLayout narrow>
    <ProtocolSidebar slot="drawer-left" :protocol="documentVersion" />
    <div class="document-designation-border" :style="`background-color: ${documentVersion.color_code}`"></div>
    <template v-slot:toolbar-actions>
      <PdfViewerActions :document="documentVersion" />
    </template>
    <PdfViewer v-if="hasVersionId" :annotate-on="documentVersion">
      <Download :url="documentVersion.file" />
    </PdfViewer>
    <VersionsSidebar slot="drawer-right" :versionable="documentVersion" />
  </BaseLayout>
</template>

<script>
import Protocol from '@/store/models/protocol'
import Meeting from '@/store/models/meeting'
import Note from '@/store/models/note'
import documentMixin from '@/components/pages/mixins/document'

export default {
  name: 'Protocol',
  mixins: [documentMixin],
  async asyncData({ id, version_id: versionId }) {
    const protocol = await Protocol.fetchVersion(id, versionId)
    const versions = await protocol.fetchVersions()
    await Promise.all([
      ...versions.map((v) => v.fetchAnnotationLayers()),
      Meeting.findOrFetch(protocol.meeting_id),
      Note.fetchAllByModel(protocol),
    ])
  },
  data() {
    return {
      documentType: 'protocol',
    }
  },
  computed: {
    document() {
      return Protocol.query().with('annotations').with('meeting')
    },
  },
}
</script>

<style lang="scss">
.document-designation-border {
  border-top: 5px solid transparent;
  height: 10px;
}
</style>
