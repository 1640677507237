import { join } from 'path'
import mqtt from 'mqtt'

export const topicHandlers = new Map()

const topics = ['tagesordnung_item/is_active_changed']

export async function initClient(options, store) {
  const url = new URL(window.location.origin)
  url.protocol = window.location.protocol.endsWith('s:') ? 'wss' : 'ws'
  url.pathname = join(options.path_prefix, 'mqtt')
  const client = await mqtt.connectAsync(url.toString(), {
    connectTimeout: 1000,
    reconnectPeriod: 10_000,
    clean: true,
    username: options.mqtt.username,
    password: options.mqtt.password,
  })

  store.commit('application/set', { path: 'mqttConnected', value: true })

  client.on('connect', () => {
    store.commit('application/set', { path: 'mqttConnected', value: true })
  })

  client.on('reconnect', () => {
    store.commit('application/set', { path: 'mqttConnected', value: false })
  })

  client.on('message', (topic, message) => {
    const handlers = Array.from(topicHandlers).flatMap(([key, callbacks]) => {
      return key.startsWith(`${topic}:`) ? callbacks : []
    })
    handlers.forEach((handler) => handler(JSON.parse(message.toString())))
  })

  await Promise.all(topics.map((topic) => client.subscribeAsync(topic)))
}

function removeMessageHandler(topic) {
  topicHandlers.delete(topic)
}

function stringToHash(string) {
  return string.split('').reduce((hash, char) => {
    return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash
  }, 0)
}

function generateKey(topic, callback) {
  return `${topic}:${stringToHash(callback.toString())}`
}

function addMessageHandler(topic, callback) {
  const handlers = topicHandlers.get(generateKey(topic, callback)) || []
  topicHandlers.set(generateKey(topic, callback), [...handlers, callback])
}

export function onMessage(topic, callback) {
  addMessageHandler(topic, callback)
}

export function offMessage(topic, callback) {
  removeMessageHandler(topic, callback)
}
