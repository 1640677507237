<template>
  <v-list-item dense :ripple="clickable" :to="to" color="primary">
    <slot />
  </v-list-item>
</template>

<script>
export default {
  name: 'LinkedListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    extraQueryParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    to() {
      if (!this.clickable) {
        return null
      }
      const url = this.item.url
      url.query = { ...url.query, ...this.extraQueryParams }
      return url
    },
  },
}
</script>
