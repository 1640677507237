export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: () => 0,
    },
    count: {
      type: Number,
      default: () => 0,
    },
  },
}
