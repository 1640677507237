<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'"
    persistent
  >
    <v-card>
      <v-toolbar v-if="$vuetify.breakpoint.xsOnly" dark color="primary">
        <v-btn icon dark @click.native="hide">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('Send_notification') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-title>
        <v-row>
          <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12">
            <h3 class="headline">{{ $t('Send_notification') }}</h3>
          </v-col>
          <v-col cols="12">
            <slot name="description"></slot>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="searchTerm"
              append-icon="mdi-magnify"
              :label="$t('share_search_label')"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              v-model="selectedPrincipals"
              :headers="headers"
              :items="principals"
              :no-data-text="$t('no_data')"
              :no-results-text="$t('no_data')"
              hide-default-footer
              item-key="title"
              disable-pagination
              :search="searchTerm"
              data-testid="principals-table"
              show-select
            >
              <template #header.data-table-select="{ props, on }">
                <v-simple-checkbox
                  :value="props.value"
                  :indeterminate="props.indeterminate"
                  :ripple="false"
                  v-on="on"
                />
              </template>
              <template #item.data-table-select="{ isSelected, select }">
                <v-simple-checkbox :value="isSelected" :ripple="false" @input="select" />
              </template>
              <template v-slot:item.type="{ item }">
                <v-icon small>{{ getIconForType(item.type) }}</v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <NotificationTextarea
              v-model="message"
              v-cmdenter="send"
              class="mt-4"
              :fragments="fragments"
              :fragment-context="fragmentContext"
              :fragment-boxes="fragmentBoxes"
              :rules="[$rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!(selectedPrincipals.length && message.length)"
          :loading="loading"
          color="primary"
          raised
          @click.stop="send"
        >
          {{ $t('Send') }}
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.smAndUp" color="primary" text @click.stop="hide">
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps" />
    </template>
  </v-dialog>
</template>

<script>
import { api } from '@/api'
import User from '@/store/models/user'

const typeIconMap = {
  user: 'mdi-account',
  group: 'mdi-account-group',
}

export default {
  name: 'NotificationDialog',
  props: {
    url: {
      type: String,
      required: true,
    },
    fragments: {
      type: Array,
      default: () => [],
    },
    fragmentContext: {
      type: Object,
      default: () => ({}),
    },
    fragmentBoxes: {
      type: Array,
      default: () => ({}),
    },
    principals: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      searchTerm: '',
      message: '',
      headers: [
        { text: this.$t('share_column_type'), align: 'middle', value: 'type', width: '1%' },
        { text: this.$t('share_column_title'), align: 'left', value: 'title' },
        { text: this.$t('share_column_role'), align: 'left', value: 'role' },
      ],
      selectedPrincipals: [],
    }
  },
  computed: {
    users() {
      return User.query().orderBy('fullName').all()
    },
  },
  methods: {
    hide() {
      this.reset()
      this.loading = false
      this.show = false
    },
    async send() {
      this.loading = true
      const data = {
        message: this.message,
        principals: this.selectedPrincipals,
      }
      await api.post(this.url, data)
      this.loading = false
      this.hide()
      this.notifySuccess(this.$t('notification_sent'))
    },
    reset() {
      this.selected = []
      this.message = ''
    },
    getIconForType(type) {
      return typeIconMap[type] || ''
    },
  },
}
</script>
