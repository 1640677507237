<template>
  <BaseLayout narrow>
    <ProposalSidebar slot="drawer-left" :proposal="documentVersion" />
    <div class="document-designation-border" :style="`background-color: ${documentVersion.color_code}`"></div>
    <template v-slot:toolbar-actions>
      <PdfViewerActions :document="documentVersion" />
    </template>
    <PdfViewer v-if="hasVersionId" :annotate-on="documentVersion">
      <Download :url="documentVersion.file" />
    </PdfViewer>
    <VersionsSidebar slot="drawer-right" :versionable="documentVersion" show-next-agenda-item-link />
  </BaseLayout>
</template>

<script>
import Proposal from '@/store/models/proposal'
import AgendaItem from '@/store/models/agendaitem'
import Note from '@/store/models/note'
import Attachment from '@/store/models/attachment'
import documentMixin from '@/components/pages/mixins/document'

export default {
  name: 'Proposal',
  mixins: [documentMixin],
  async asyncData({ id, version_id: versionId }) {
    const proposal = await Proposal.fetchVersion(id, versionId)
    const [versions, agendaItem] = await Promise.all([
      proposal.fetchVersions(),
      AgendaItem.findOrFetch(proposal.agenda_item_id),
    ])
    await Promise.all([
      ...versions.map((v) => v.fetchAnnotationLayers()),
      Attachment.fetchAllByModel(agendaItem),
      Note.fetchAllByModel(proposal),
    ])
  },
  data() {
    return {
      documentType: 'proposal',
    }
  },
  computed: {
    document() {
      return Proposal.query().with('annotations').with('agenda_item')
    },
  },
  head() {
    return {
      title() {
        return { inner: this.documentVersion.agenda_item.title }
      },
    }
  },
}
</script>

<style lang="scss">
.document-designation-border {
  border-top: 5px solid transparent;
  height: 10px;
}
</style>
