<template>
  <div class="flex flex-col">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('share_search_label')"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="principals"
      :no-data-text="$t('no_data')"
      hide-default-footer
      item-key="title"
      disable-pagination
      :search="search"
      data-testid="principals-table"
    >
      <template v-slot:item.selected="{ item }">
        <v-simple-checkbox v-model="item.selected" primary />
      </template>
      <template v-slot:item.type="{ item }">
        <v-icon small>{{ getIconForType(item.type) }}</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
const typeIconMap = {
  user: 'mdi-account',
  group: 'mdi-account-group',
}

export default {
  name: 'PrincipalTable',
  props: {
    principals: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    }
  },
  methods: {
    getIconForType(type) {
      return typeIconMap[type] || ''
    },
  },
}
</script>
