<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-form :disabled="loading" @submit.prevent="submit">
      <v-card>
        <v-card-title>
          <h3 class="headline" v-text="instance ? instance.title : $t('committee_links_add_link')" />
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="formData.title"
            :label="$t('committee_links_field_title')"
            :error-messages="errors.title"
            autofocus
          />
          <v-text-field
            v-model="formData.url"
            :label="$t('committee_links_field_url')"
            :error-messages="errors.url"
            placeholder="https://…"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text :disabled="loading" @click="close">
            <span v-text="$t('Cancel')" />
          </v-btn>
          <v-btn color="primary" type="submit" depressed :loading="loading">
            <span v-text="$t('Save')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { api } from '@/api'
import { createLink } from '@/api/helpers'

export default {
  name: 'CommitteeLinkEditDialog',
  props: {
    committee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      instance: null,
      loading: false,
      dialog: false,
      formData: {},
      errors: {},
    }
  },
  computed: {
    defaultFormData() {
      return { title: '', url: '' }
    },
  },
  watch: {
    instance: {
      handler() {
        this.reset()
      },
      immediate: true,
    },
  },
  methods: {
    open(instance = null) {
      this.instance = instance
      this.dialog = true
    },
    reset() {
      this.formData = cloneDeep(this.instance || this.defaultFormData)
      this.errors = {}
    },
    close() {
      this.dialog = false
      this.reset()
    },
    onSuccess(response) {
      this.notifySuccess(this.$t('committee_links_save_success'))
      this.$emit('success', response)
      this.close()
    },
    onFormError(error) {
      this.errors = error.response.data
      if (error.isAxiosError && get(error, 'response.status') === 403) {
        this.notifyError(error.response.data.detail)
      }
      if (error.response.data.non_field_errors) {
        this.notifyError(error.response.data.non_field_errors[0])
      }
    },
    onServerError() {
      this.notifyError(this.$t('committee_links_save_error'))
    },
    async submit() {
      this.loading = true
      try {
        let response
        if (this.instance) {
          const url = createLink('committees/{id}/committee_links/{instanceId}/', {
            id: this.committee.id,
            instanceId: this.instance.id,
          })
          response = await api.patch(url, this.formData)
        } else {
          const url = createLink('committees/{id}/committee_links/', {
            id: this.committee.id,
          })
          response = await api.post(url, this.formData)
        }
        this.onSuccess(response)
      } catch (error) {
        if (error.isAxiosError && error.response.status >= 400 && error.response.status < 500) {
          this.onFormError(error)
        } else {
          this.onServerError(error)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
