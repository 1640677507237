<template>
  <Sidebar>
    <DividedSidebarList
      :title="$t('Back_to_committee')"
      :subtitle="committee.title"
      :parent="committee"
      empty-message=""
    />
  </Sidebar>
</template>
<script>
import Meeting from '@/store/models/meeting'
import mixin from '@/components/sidebars/mixin'

export default {
  name: 'MeetingsSidebar',
  mixins: [mixin],
  computed: {
    committee() {
      return Meeting.query().with('committee').find(this.$store.state.route.params.id).committee
    },
  },
}
</script>
