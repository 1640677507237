import { resolveIcon } from '@/mimetype-registry'
import Versionable from './versionable'
import Annotationable from './mixins/annotationable'

const pdfIcon = resolveIcon('.pdf')
class PersonalDocument extends Versionable {
  static entity = 'personaldocument'

  // This matches both the LIST endpoint on the containing AgendaItemViewset
  // AND the DESTROY and RETRIEVE endpoints of the PersonalDocumentViewset
  static apiPath = 'personal_documents'
  static primaryKey = 'version_id'

  USE_PSPDFKIT = true

  static fields() {
    return {
      id: this.attr(null),
      title: this.string(''),
      user: this.attr(null),
      file: this.string(''),
      agenda_item_id: this.number(0),
      agenda_item: this.belongsTo('agendaitem', 'agenda_item_id'),
      updated: this.attr(null),
      version_id: this.attr(null),
      has_annotations: this.boolean(false),
      last_seen: this.attr(null),
      annotationLayers: this.morphMany('annotation_layer', 'object_id', 'content_type'),
      selected_layer: this.attr(null),
      meeting_id: this.attr(null),
    }
  }

  get meetingId() {
    return this.meeting_id
  }

  get url() {
    return {
      name: 'personaldocument',
      params: { id: this.id },
    }
  }

  get icon() {
    // We only allow PDF uploads, so no need to look up the icon
    return pdfIcon
  }
}

Object.assign(PersonalDocument.prototype, Annotationable)

export default PersonalDocument
