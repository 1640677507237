<template>
  <v-list-group :value="isVersionActive">
    <template v-slot:activator>
      <v-list-item-content data-testid="version-list-item" class="px-3">
        <div class="d-flex">
          <router-link :to="item.url" exact :class="versionActiveClass" class="text-decoration-none">
            <v-list-item-title> Version {{ count - index }} </v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              {{ $t('last_update_on', { date: toLocaleDateTimeString(item.created) }) }}
            </v-list-item-subtitle>
          </router-link>
          <ListActionIndicator :item="item" :is-commented-function="isCommented" />
        </div>
      </v-list-item-content>
    </template>
    <v-divider />
    <LayerListItem v-for="layer in layers" :key="layer.listKey" :layer="layer" :version="item" />
  </v-list-group>
</template>

<script>
import orderBy from 'lodash/orderBy'
import mixin from '@/components/listitems/mixin'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'VersionListItem',
  mixins: [mixin],
  computed: {
    isVersionActive() {
      if (!this.$route.query.version_id) {
        return false
      }
      return this.item.version_id.toString() === this.$route.query.version_id.toString()
    },
    versionActiveClass() {
      if (this.isVersionActive) {
        return 'primary--text'
      }
      return 'black--text'
    },
    layers() {
      return orderBy(this.item.annotationLayers, ['is_private_layer', 'updated'], ['desc', 'desc'])
    },
  },
  methods: {
    toLocaleDateTimeString,
    isCommented(item) {
      return item.annotationLayers.some((l) => l.has_annotations)
    },
  },
}
</script>
