import Base from './base'

export default class Membership extends Base {
  static entity = 'membership'

  static apiPath = 'memberships'

  static fields() {
    return {
      id: this.attr(null),
      committee_id: this.attr(null),
      user: this.attr(null),
      first_name: this.string(''),
      last_name: this.string(''),
      begin_date: this.attr(null),
      end_date: this.attr(null),
      roles: this.attr(null),
      roles_display: this.attr(null),
      is_responsible: this.attr(null),
    }
  }
}
