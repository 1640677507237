import User from '@/store/models/user'

export default class Profile extends User {
  static entity = 'profile'

  static apiPath = 'profile'

  hasRole(role) {
    return !!User.query()
      .where('id', this.id)
      .where('roles', (roles) => roles.includes(role))
      .count()
  }
}
