/**
 * This class extends the base class to provide a version method
 * which is able to fetch document versions.
 */
import axios from 'axios'
import joinPath from 'path.join'
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import { resolveIcon } from '@/mimetype-registry'
import AnnotationLayer from '@/store/models/annotation_layer'
import Base from './base'

export const blobHTTPClient = axios.create({
  responseType: 'blob',
  withCredentials: true,
  header: {
    'Content-Type': 'multipart/form-data',
  },
})

export default class Versionable extends Base {
  static async fetch(id) {
    const { data } = await api.get(createLink(joinPath(this.apiPath, String(id || ''))))
    await this.dispatch('insertOrUpdate', { data })
    return this.query().where('id', Number.parseInt(id, 10)).orderBy('created', 'desc').first()
  }

  static findOrFetch(id) {
    const record = this.query().where('id', Number.parseInt(id, 10)).first()
    return record ? Promise.resolve(record) : this.fetch(id)
  }

  async fetchVersions() {
    const { data } = await api.get(createLink(joinPath(this.idPath, 'versions')))
    const insertedData = await this.$insertOrUpdate({ data })
    return insertedData[this.constructor.entity]
  }

  static async fetchVersion(id, versionId) {
    let obj
    if (versionId) {
      const { data } = await api.get(
        createLink(joinPath(this.apiPath, id.toString(), 'versions', versionId.toString())),
      )
      obj = data
    } else {
      const { data } = await api.get(createLink(joinPath(this.apiPath, id.toString())))
      obj = data
    }
    const insertedData = await this.insertOrUpdate({ data: obj })
    return insertedData[this.entity][0]
  }

  async fetchVersionDocument() {
    const { data } = await blobHTTPClient.get(this.file)
    return data
  }

  async fetchAnnotations(layer) {
    const path = joinPath('v1', 'annotationlayers', layer.toString(), 'annotations')
    const { data } = await api.get(path)
    return data
  }

  async createOrUpdateAnnotation(layerId, annotation) {
    const path = joinPath('v1', 'annotationlayers', layerId.toString(), 'annotation_create')
    const {
      data: {
        version_has_annotations: versionHasAnnotations,
        layer_has_annotations: layerHasAnnotations,
        document_has_annotations: documentHasAnnotations,
      },
    } = await api.post(path, { annotation })
    this.$query()
      .where('id', this.id)
      .orderBy('version_id', 'desc')
      .first()
      .$update({ has_annotations: documentHasAnnotations })
    this.$update({ has_annotations: versionHasAnnotations })
    AnnotationLayer.find(layerId).$update({ has_annotations: layerHasAnnotations })
  }

  async deleteAnnotation(annotationId) {
    const path = joinPath('v1', 'annotations', annotationId)
    const {
      data: {
        version_has_annotations: versionHasAnnotations,
        layer_has_annotations: layerHasAnnotations,
        document_has_annotations: documentHasAnnotations,
        layer_id: layerId,
      },
    } = await api.delete(path)
    this.$query()
      .where('id', this.id)
      .orderBy('version_id', 'desc')
      .first()
      .$update({ has_annotations: documentHasAnnotations })
    this.$update({ has_annotations: versionHasAnnotations })
    AnnotationLayer.find(layerId).$update({ has_annotations: layerHasAnnotations })
  }

  get idPath() {
    return joinPath(this.path, this.id.toString())
  }

  /**
   * The versions are defined by the same id value.
   * Only the version_id is distinct.
   */
  get versions() {
    return this.$query().where('id', this.id).with('annotationLayers').orderBy('created', 'desc').all()
  }

  get listKey() {
    return `${this.constructor.entity}-${this.version_id}`
  }

  get url() {
    return {
      name: this.constructor.entity,
      params: { id: this.id },
      query: { version_id: this.version_id, layer_id: this.selected_layer },
    }
  }

  get mimeTypeIcon() {
    return resolveIcon(this.file_extension)
  }
}
