<template>
  <v-btn color="green" dark fixed bottom right fab @click.prevent.stop="download">
    <v-icon>mdi-cloud-download-outline</v-icon>
  </v-btn>
</template>

<script>
import downloadFile from '@/download'

export default {
  name: 'DownloadDocument',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    download() {
      downloadFile(this.url)
    },
  },
}
</script>
