<template>
  <v-list two-line class="py-0">
    <slot name="top" />
    <template v-if="listItem && items.length > 0">
      <template v-for="(item, index) in items">
        <component :is="listItem" :key="item.listKey" :item="item" :index="index" :count="items.length" />
        <v-divider v-if="index + 1 < items.length" :key="index"></v-divider>
      </template>
    </template>
    <p v-if="emptyMessageText && items.length === 0" class="mb-0 mt-2 mx-2 text-xs-center grey--text">
      {{ emptyMessageText }}
    </p>
    <slot name="bottom" />
  </v-list>
</template>

<script>
import mixin from '@/components/lists/mixin'

export default {
  name: 'DividedList',
  mixins: [mixin],
}
</script>
