import { filterLatestVersions } from '@/version_utils'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    filterLatestVersions,
  },
}
