<template>
  <v-dialog v-model="isOpen" width="50%" persistent>
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps" />
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('post_suggestion') }}</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column">
          <v-form class="d-flex flex-column">
            <v-text-field v-model="title" :label="$t('Business_case_title')" :rules="[$rules.required]" required>
            </v-text-field>
            <v-text-field v-model="businessNumber" :label="$t('Business_number')"></v-text-field>
            <v-textarea v-model="description" :label="$t('Wording')"> </v-textarea>
            <div v-for="(file, i) in files" :key="`file-${i}`" class="d-flex">
              <input
                ref="fileUpload"
                type="file"
                class="file-upload-file-input"
                @change="fileUploadHandler($event, i)"
              />
              <v-text-field v-model="file.file" disabled class="file-upload-file-path"> </v-text-field>
              <v-btn text class="file-upload-select-button" @click="openFileDialog(i)">
                {{ $t('Select_file') }}
              </v-btn>
            </div>
          </v-form>
          <v-alert class="alert-message" :value="!!errorMessage" type="error" outlined>
            {{ errorMessage }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close">{{ $t('Cancel') }}</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          raised
          data-testid="submit-ris-suggestion"
          @click="postSuggestion"
        >
          {{ $t('post_suggestion') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import Committee from '@/store/models/committee'

const postSuggestionUrl = '/ris/post_suggestion/{committee}/'

export default {
  name: 'RisSuggestion',
  props: {
    committee: {
      type: Committee,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      files: [{ file: '' }, { file: '' }, { file: '' }],
      errorMessage: '',
    }
  },
  computed: {
    ...mapFields('rissuggestion', ['title', 'description', 'businessNumber']),
    valid() {
      // The title is required, as well as the description or a file.
      return (
        this.$rules.required(this.title) === true &&
        (this.$rules.required(this.description) === true || this.files.filter((f) => f.file !== '').length)
      )
    },
  },
  methods: {
    ...mapActions({
      reset: 'rissuggestion/reset',
    }),
    openFileDialog(index) {
      // Open the file dialog of the correct upload field.
      this.$refs.fileUpload[index].click()
    },
    fileUploadHandler(e, index) {
      // Set the filename in the correct display field.
      const { files } = e.target
      this.files[index].file = files.length ? files[0].name : ''
    },
    close() {
      // Close the overlay, reset file fields and remove error messages.
      this.isOpen = false
      /* eslint-disable no-param-reassign */
      this.$refs.fileUpload.forEach((f) => {
        f.value = ''
      })
      this.files.forEach((f) => {
        f.file = ''
      })
      /* eslint-enable no-param-reassign */
      this.errorMessage = ''
      this.loading = false
    },
    async postSuggestion() {
      if (!this.valid) {
        return
      }
      // Collect form data and add additional fields (files).
      const formData = new FormData()
      formData.set('title', this.title)
      formData.set('description', this.description)
      formData.set('business_number', this.businessNumber)
      this.getFiles().forEach((f) => {
        formData.append('files', f)
      })
      this.loading = true
      try {
        // Post to RIS.
        const response = await api.post(createLink(postSuggestionUrl, { committee: this.committee.id }), formData)

        // If the response is successful but does not contain an object with key "title", the WAF is
        // intercepting the request and blocking it with status code 200 (!) ...
        if (response.data.title === undefined) {
          const error = new Error(response.data)
          error.type = 'WAFException'
          throw error
        }

        // If successful, reset localStorage and close overlay.
        this.$emit('posted', response)
        this.reset()
        this.close()
      } catch (e) {
        // Show errors.
        if (e.response) {
          this.errorMessage = e.response.data.detail
        }

        // If the WAF intercepted the request, show an error message and re-raise the error to alert
        // the devs.
        if (e.type === 'WAFException') {
          this.errorMessage = 'Connection Error (WAF)'
          throw new Error(`WAFException: ${e}`)
        }

        this.loading = false
      }
    },
    getFiles() {
      // Return an array of files (non-empty file fields).
      if (this.$refs.fileUpload) {
        return this.$refs.fileUpload.map((f) => (f.files.length ? f.files[0] : null)).filter((f) => f !== null)
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped>
.file-upload-select-button {
  position: relative;
  overflow: hidden;
  margin: 10px 0 10px 10px;
}
.file-upload-file-input {
  display: none;
}
</style>
