<template>
  <div>
    <v-btn-toggle v-model="selectedTags" tile dense color="primary" multiple class="flex-wrap" @change="save">
      <v-btn
        v-for="tag in options"
        :key="tag.slug"
        small
        :value="tag.slug"
        class="mr-4 rounded-xl pl-3 pr-8 mt-2"
        style="position: relative"
      >
        <span data-testid="tag-field-selectable-tag"
          >{{ tag.name
          }}<template v-if="showRemainingTaggers(tag.slug)">
            {{
              $t('tag_field_selectable_tag', {
                selectedCount: tagCountForSlug(tag.slug),
                availableCount: taggerCountForSlug(tag.slug),
              })
            }}
          </template>
        </span>
        <v-btn
          :class="revealTagColor(tag)"
          icon
          class="reveal-tag-button"
          width="28px"
          height="28px"
          :value="null"
          data-testid="tag-field-reveal-tag"
          @click.stop.capture.prevent="revealTag(tag)"
          ><v-icon color="white" size="32px">mdi-information-symbol</v-icon></v-btn
        ></v-btn
      >
    </v-btn-toggle>
    <v-list v-if="revealedTag" two-line class="py-0">
      <v-list-item v-for="tagged in taggedForSlug(revealedTag.slug)" :key="tagged.username">
        <v-list-item-content class="py-2">
          <v-list-item-title data-testid="tag-field-tagger"
            >{{ tagged.full_name }} ({{ tagged.username }})</v-list-item-title
          >
          <v-list-item-subtitle>
            {{ toLocaleDateTimeString(tagged.created) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import filter from 'lodash/filter'
import Profile from '@/store/models/profile'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import find from 'lodash/find'
import size from 'lodash/size'
import get from 'lodash/get'
import { toLocaleDateTimeString } from '@/filters'

export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTags: [],
      revealedTag: null,
    }
  },
  computed: {
    hasTags() {
      return !isEmpty(this.options)
    },
    username() {
      return Profile.query().first().username
    },
  },
  watch: {
    tags: {
      handler(tags) {
        this.selectedTags = map(filter(tags, { username: this.username }), 'slug')
      },
      immediate: true,
    },
  },
  methods: {
    toLocaleDateTimeString,
    save(tags) {
      this.$emit('save', tags)
    },
    taggerCountForSlug(slug) {
      return get(find(this.options, { slug }), 'tagger_count', 0)
    },
    tagCountForSlug(slug) {
      return size(filter(this.tags, { slug }))
    },
    showRemainingTaggers(slug) {
      return this.taggerCountForSlug(slug) > 1
    },
    taggedForSlug(slug) {
      return filter(this.tags, { slug })
    },
    hasTaggersForSlug(slug) {
      return size(this.taggedForSlug(slug)) > 0
    },
    revealTagColor(tag) {
      if (!this.revealedTag) {
        return 'info'
      }
      return tag.slug === this.revealedTag.slug ? 'warning' : 'info'
    },
    revealTag(tag) {
      if (!this.hasTaggersForSlug(tag.slug)) {
        return
      }
      if (this.revealedTag && this.revealedTag.slug === tag.slug) {
        this.revealedTag = null
      } else {
        this.revealedTag = tag
      }
    },
  },
}
</script>
<style>
.reveal-tag-button {
  position: absolute;
  right: -40px;
  z-index: 1;
  min-width: 0 !important;
}
</style>
