<template>
  <div v-if="hasTags" class="d-flex pt-2">
    <v-tooltip v-for="tag in uniqueTags" :key="tag.slug" bottom>
      <template #activator="{ on, attrs }">
        <v-progress-linear
          v-bind="attrs"
          height="18"
          :value="taggerProgressForSlug(tag.slug)"
          data-testid="tag-list-tag"
          color="blue darken-2"
          background-color="blue lighten-1"
          x-small
          class="tag-list-progress mr-1 caption rounded-xl white--text text-uppercase font-weight-bold"
          v-on="on"
          >{{ tag.name }}</v-progress-linear
        >
      </template>
      <span
        >{{ tag.name }}:
        {{
          $t('tag_field_selectable_tag', {
            selectedCount: taggedCountForSlug(tag.slug),
            availableCount: taggerCountForSlug(tag.slug),
          })
        }}</span
      >
    </v-tooltip>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import size from 'lodash/size'
import filter from 'lodash/filter'

export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    uniqueTags() {
      return uniqBy(this.tags, 'name')
    },
    hasTags() {
      return !isEmpty(this.tags)
    },
  },
  methods: {
    taggedCountForSlug(slug) {
      return size(filter(this.tags, { slug }))
    },
    taggerCountForSlug(slug) {
      return this.$store.getters['tags/getTagForSlug'](slug).tagger_count
    },
    taggerProgressForSlug(slug) {
      return (this.taggedCountForSlug(slug) / this.taggerCountForSlug(slug)) * 100
    },
  },
}
</script>
<style>
.tag-list-progress {
  max-width: 180px;
  letter-spacing: 1px !important;

  .v-progress-linear__content {
    display: block;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    left: 10px;
    right: 10px;
    text-align: center;
  }
}
</style>
