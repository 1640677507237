import i18n from '@/i18n'
import { toLocaleDateTimeString } from '@/filters'
import Versionable from './versionable'
import Annotationable from './mixins/annotationable'

class Protocol extends Versionable {
  static entity = 'protocol'

  static apiPath = 'protocols'

  static primaryKey = 'version_id'

  static fields() {
    return {
      id: this.attr(null),
      created: this.attr('', (v) => new Date(v)),
      updated: this.attr('', (v) => new Date(v)),
      file: this.string(''),
      meeting_id: this.number(0),
      meeting: this.belongsTo('meeting', 'meeting_id', 'id'),
      version_id: this.attr(null),
      annotations: this.morphMany('note', 'object_id', 'content_type', 'id'),
      file_extension: this.attr(null),
      last_seen: this.attr(null),
      annotationLayers: this.morphMany('annotation_layer', 'object_id', 'content_type'),
      selected_layer: this.attr(null),
      color_code: this.attr(null),
      tags: this.attr([]),
    }
  }

  get title() {
    const meeting = this.constructor.relation('meeting')
    return i18n.t('Protocol_of', { date: toLocaleDateTimeString(meeting.start) })
  }

  get breadcrumbText() {
    return this.title
  }

  get meetingId() {
    return this.meeting_id
  }
}

Object.assign(Protocol.prototype, Annotationable)

export default Protocol
