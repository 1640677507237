<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" offset-y nudge-top="20">
      <template #activator="{ on, attrs }">
        <v-text-field :label="buttonLabel" v-bind="attrs" hide-details append-icon="mdi-menu-down" v-on="on" />
      </template>
      <v-card>
        <v-card-title>
          <v-row column>
            <v-col class="d-flex align-center" cols="12">
              <CalendarField v-model="minDate" :max="maxDate" :placeholder="$t('dateFieldFilter.from')" class="grow" />
              <span class="mx-3">-</span>
              <CalendarField v-model="maxDate" :min="minDate" :placeholder="$t('dateFieldFilter.to')" class="grow" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="6" class="pb-0 pr-1">
              <v-btn block small depressed class="mr-2" @click="setCurrentMonth">
                {{ $t('dateFieldFilter.currentMonth') }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="pb-0 pl-1">
              <v-btn block small depressed class="ml-2" @click="setLastMonth">
                {{ $t('dateFieldFilter.lastMonth') }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="pr-1">
              <v-btn block small depressed class="mr-2" @click="setCurrentYear">
                {{ $t('dateFieldFilter.currentYear') }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="pl-1">
              <v-btn block small depressed class="ml-2" @click="setLastYear">
                {{ $t('dateFieldFilter.lastYear') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="success" depressed @click="applyRange">{{ $t('dateFieldFilter.apply') }}</v-btn>
          <v-btn color="error" depressed @click="resetRange">{{ $t('dateFieldFilter.reset') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { toLocaleDateString } from '@/filters'

export default {
  name: 'DateRangeFilterField',
  props: {
    min: {
      type: String,
      default: () => '',
    },
    max: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      minDate: this.min,
      maxDate: this.max,
    }
  },
  computed: {
    labelRange() {
      if (!this.min && !this.max) {
        return this.$t('dateFieldFilter.noRange')
      }
      const minString = toLocaleDateString(this.min)
      const maxString = toLocaleDateString(this.max)
      if (this.min && !this.max) {
        return this.$t('dateFieldFilter.after', { min: minString })
      }
      if (!this.min && this.max) {
        return this.$t('dateFieldFilter.before', { max: maxString })
      }
      return `${minString} - ${maxString}`
    },
    buttonLabel() {
      return `${this.label}: ${this.labelRange}`
    },
  },
  methods: {
    setCurrentMonth() {
      this.minDate = DateTime.local().startOf('month').toISODate()
      this.maxDate = DateTime.local().endOf('month').toISODate()
    },
    setLastMonth() {
      this.minDate = DateTime.local().minus({ months: 1 }).startOf('month').toISODate()
      this.maxDate = DateTime.local().minus({ months: 1 }).endOf('month').toISODate()
    },
    setCurrentYear() {
      this.minDate = DateTime.local().startOf('year').toISODate()
      this.maxDate = DateTime.local().endOf('year').toISODate()
    },
    setLastYear() {
      this.minDate = DateTime.local().minus({ years: 1 }).startOf('year').toISODate()
      this.maxDate = DateTime.local().minus({ years: 1 }).endOf('year').toISODate()
    },
    resetRange() {
      this.minDate = ''
      this.maxDate = ''
      this.applyRange()
    },
    applyRange() {
      this.$emit('update:min', this.minDate)
      this.$emit('update:max', this.maxDate)
      this.menu = false
    },
  },
}
</script>
