function debounce(func) {
  return () => {
    window.requestAnimationFrame(func)
  }
}

function onScroll(el, callback) {
  el.addEventListener('scroll', debounce(callback))
}

function bind(el, binding) {
  onScroll(el, binding.value)
}

export default {
  name: 'onscroll',
  bind,
}
