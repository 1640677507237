<template>
  <Sidebar>
    <DividedSidebarList
      :title="$t('Back_to_meeting')"
      :subtitle="toLocaleDateTimeString(meeting.start)"
      :parent="meeting"
      empty-message=""
    />
  </Sidebar>
</template>

<script>
import mixin from '@/components/sidebars/mixin'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'ProtocolSidebar',
  mixins: [mixin],
  props: {
    protocol: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      meeting: this.protocol.meeting,
    }
  },
  methods: {
    toLocaleDateTimeString,
  },
}
</script>
