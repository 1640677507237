<template>
  <v-dialog v-model="isOpen" width="800">
    <v-form ref="form" v-model="valid" @submit.prevent="save">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-alert v-if="hasNonFieldErrors" type="error">
            <ul>
              <li v-for="error in nonFieldErrors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>
          <v-autocomplete
            v-if="hasMeetingChoices"
            v-model="meeting"
            :items="meetingChoices"
            :label="$t('Business_case_meeting')"
            :rules="[$rules.required]"
            item-text="title"
            item-value="id"
            return-object
            required
            clearable
            :error-messages="errorsForField('meeting')"
          />
          <v-autocomplete
            v-model="vorstoss.business_type"
            :items="businessTypeChoices"
            :label="$t('Business_type')"
            :rules="[$rules.required]"
            return-object
            required
            clearable
            :error-messages="errorsForField('business_type')"
          />
          <v-text-field
            v-model="vorstoss.title"
            :label="$t('Business_case_title')"
            :rules="[$rules.required]"
            required
            :error-messages="errorsForField('title')"
          />
          <v-textarea
            v-model="vorstoss.description"
            :label="$t('Wording')"
            :rules="[$rules.required]"
            required
            :error-messages="errorsForField('description')"
          />
          <v-textarea v-model="vorstoss.reason" :label="$t('Business_case_reason')" />
          <v-autocomplete
            v-model="firstSignatory"
            :items="committeeMembers"
            :label="$t('Business_case_proposal_creator')"
            :rules="[$rules.required]"
            required
            :disabled="!user.can_create_proposals_for_other_people"
            item-value="id"
            :item-text="signatoryLabel"
            return-object
          />
          <v-autocomplete
            v-model="secondSignatory"
            :items="committeeMembers"
            :label="$t('Business_case_second_signature')"
            clearable
            item-value="id"
            :item-text="signatoryLabel"
            return-object
          />
          <v-autocomplete
            v-model="thirdSignatory"
            :items="committeeMembers"
            :label="$t('Business_case_third_signature')"
            clearable
            item-value="id"
            :item-text="signatoryLabel"
            return-object
          />
          <v-autocomplete
            v-model="otherSignatories"
            :items="committeeMembers"
            :label="$t('Business_case_other_signature')"
            clearable
            item-value="id"
            :item-text="signatoryLabel"
            return-object
            multiple
          />
          <v-select
            v-model="vorstoss.rights_holder"
            :items="rightsHolderOptions"
            :label="$t('Business_case_rights_holder')"
            clearable
          />
          <div class="d-flex">
            <v-checkbox v-model="vorstoss.is_urgent" class="mr-3" :label="$t('mark_urgent')" />
            <v-checkbox v-model="vorstoss.has_signature_sheet" :label="$t('mark_signature_sheet')" />
          </div>
          <v-file-input v-model="attachments" small-chips multiple :label="$t('Select_file')" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="error" @click="cancel">{{ $t('Cancel') }}</v-btn>
          <v-btn text type="submit" data-testid="save-vorstoss" color="primary">{{ saveActionLabel }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import Profile from '@/store/models/profile'
import Meeting from '@/store/models/meeting'
import Committee from '@/store/models/committee'
import find from 'lodash/find'
import User from '@/store/models/user'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import isUndefined from 'lodash/isUndefined'

function vorstossFactory() {
  return { signatories: [{ id: Profile.query().first().id, role: 'FIRST_SIGNATORY' }] }
}

export default {
  name: 'VorstossDialog',
  data() {
    return {
      isOpen: false,
      vorstoss: {},
      valid: null,
      rightsHolderOptions: [
        { text: this.$t('Business_case_proposal_creator'), value: 'proposal_creator' },
        { text: this.$t('Business_case_faction'), value: 'faction' },
        { text: this.$t('Business_case_commission'), value: 'commission' },
        { text: this.$t('Business_case_cross_party'), value: 'cross_party' },
      ],
      errors: {},
      businessTypeChoices: [],
      meetingChoices: [],
      meeting: {},
      attachments: [],
    }
  },
  computed: {
    committeeMembers() {
      return User.all().filter((user) => user.roles.includes('member'))
    },
    user() {
      return Profile.query().first()
    },
    firstSignatory: {
      get() {
        return this.getSignatory('FIRST_SIGNATORY')
      },
      set(signatory) {
        this.setSignatory(signatory, 'FIRST_SIGNATORY')
      },
    },
    secondSignatory: {
      get() {
        return this.getSignatory('SECOND_SIGNATORY')
      },
      set(signatory) {
        this.setSignatory(signatory, 'SECOND_SIGNATORY')
      },
    },
    thirdSignatory: {
      get() {
        return this.getSignatory('THIRD_SIGNATORY')
      },
      set(signatory) {
        this.setSignatory(signatory, 'THIRD_SIGNATORY')
      },
    },
    otherSignatories: {
      get() {
        return filter(this.vorstoss.signatories, { role: 'OTHER_SIGNATORY' })
      },
      set(signatories) {
        this.vorstoss.signatories = [
          ...signatories.map((signatory) => ({ id: signatory.id, role: 'OTHER_SIGNATORY' })),
          ...this.vorstoss.signatories.filter((signatory) => signatory.role !== 'OTHER_SIGNATORY'),
        ]
      },
    },
    nonFieldErrors() {
      return this.errorsForField('non_field_errors')
    },
    hasNonFieldErrors() {
      return !isEmpty(this.nonFieldErrors)
    },
    isInEditMode() {
      return !isUndefined(this.vorstoss.id)
    },
    dialogTitle() {
      return this.isInEditMode ? this.$t('updateVorstossTitle') : this.$t('createVorstossTitle')
    },
    saveActionLabel() {
      return this.isInEditMode ? this.$t('modifyVorstossButton') : this.$t('newVorstossButton')
    },
    saveSuccessMessage() {
      return this.isInEditMode ? this.$t('vorstossCardModifySuccess') : this.$t('vorstossCardCreateSuccess')
    },
    saveErrorMessage() {
      return this.isInEditMode ? this.$t('vorstossCardModifyError') : this.$t('vorstossCardCreateError')
    },
    hasMeetingChoices() {
      return !isEmpty(this.meetingChoices)
    },
  },
  methods: {
    signatoryLabel(signatory) {
      return [signatory.first_name, signatory.last_name].filter(Boolean).join(' ') + ` (${signatory.username})`
    },
    getSignatory(role) {
      return find(this.vorstoss.signatories, { role })
    },
    setSignatory(signatory, role) {
      this.vorstoss.signatories = [
        { id: signatory.id, role },
        ...this.vorstoss.signatories.filter((currentSignatory) => currentSignatory.role !== role),
      ]
    },
    cancel() {
      this.$refs.form.resetValidation()
      this.isOpen = false
    },
    errorsForField(field) {
      return get(this.errors, field, [])
    },
    async attachAttachments(vorstoss) {
      const formData = new FormData()
      this.attachments.forEach((attachment) => formData.append('attachments', attachment))
      await api.patch(createLink('vorstoss/{id}/attach_attachments', { id: vorstoss.id }), formData)
    },
    async save() {
      try {
        const { data: vorstoss } = this.isInEditMode
          ? await api.patch(createLink('vorstoss/{id}/', { id: this.vorstoss.id }), this.vorstoss)
          : await api.post(createLink('vorstoss/'), { meeting: this.meeting.id, ...this.vorstoss })
        await this.attachAttachments(vorstoss)
        this.$emit('update')
        this.notifySuccess(this.saveSuccessMessage)
        this.cancel()
      } catch (error) {
        this.errors = get(error, 'response.data')
        this.notifyError(this.saveErrorMessage)
        throw error
      }
    },
    async openWithCommittee(committee) {
      await User.fetchAllByModel(committee)
      await Meeting.fetchAllByModel(committee)
      this.meetingChoices = Meeting.query().where('committee_id', committee.id).all()
      this.businessTypeChoices = get(committee, 'ris_proposal_business_types', [])
      this.vorstoss = vorstossFactory()
      this.isOpen = true
    },
    async openWithMeeting(meeting) {
      this.meeting = meeting
      const committee = await Committee.findOrFetch(meeting.committee_id)
      await User.fetchAllByModel(committee)
      this.businessTypeChoices = get(committee, 'ris_proposal_business_types', [])
      this.vorstoss = vorstossFactory()
      this.isOpen = true
    },
    async openWithVorstoss(vorstoss) {
      this.meeting = await Meeting.findOrFetch(vorstoss.meeting_id)
      const committee = await Committee.findOrFetch(this.meeting.committee_id)
      await User.fetchAllByModel(committee)
      this.businessTypeChoices = get(committee, 'ris_proposal_business_types', [])
      this.vorstoss = vorstoss
      this.attachments = vorstoss.attachments
      this.isOpen = true
    },
  },
}
</script>
