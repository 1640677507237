<template>
  <div>
    <DateRangeFilterField :label="$t('dateFieldFilter.label')" :max.sync="beginDateMax" :min.sync="beginDateMin" />
  </div>
</template>

<script>
import dateTimeFilterMixin from '@/components/search/mixins/datetimefilter'

export default {
  name: 'MeetingFacets',
  mixins: [dateTimeFilterMixin],
  props: {
    facets: {
      type: Object,
      default: () => ({}),
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
