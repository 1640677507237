import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import de from 'vuetify/lib/locale/de'
import SVGIcon from '@4tw/vue-svg-icon'
import '@mdi/font/css/materialdesignicons.css'
import { Ripple } from 'vuetify/lib/directives'
import mimetypeRegistry from '../mimetype-registry'

const mimetypeIcons = Object.entries(mimetypeRegistry).reduce(
  (iconset, [extension, icon]) =>
    Object.assign(iconset, {
      [extension]: {
        component: SVGIcon,
        props: {
          name: icon,
        },
      },
    }),
  {},
)

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
})

export default function createVuetify(config = {}) {
  return new Vuetify({
    icons: {
      values: {
        appIcon: {
          component: SVGIcon,
          props: {
            name: 'app-icon',
          },
        },
        ...mimetypeIcons,
      },
      iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
      themes: {
        light: {
          primary: config.primary_color || colors.lightBlue.accent3,
          accent: config.primary_color || colors.lightBlue.accent3, // same as primary
          activelabel: colors.lightBlue.accent3,
          info: config.primary_color || colors.lightBlue.accent3, // same as primary
          success: colors.green.accent4,
          warning: colors.amber.accent3,
          error: colors.red.accent3,

          appbarcolor: config.primary_color || colors.blueGrey.darken3,
          bodyBg: colors.blueGrey.lighten5,
          bodylight: '#5f7481',
          bodydark: colors.blueGrey.darken3,
          bodylightText: '#5f7481',
          greydark: colors.blueGrey.lighten5,
          greylight: '#fcfcff',
        },
      },
      options: {
        customProperties: true,
      },
    },
    lang: {
      locales: { de },
      current: 'de',
    },
  })
}
