<template>
  <BaseLayout :breadcrumbs="breadcrumbs">
    <Sidebar slot="drawer-left">
      <DividedSidebarList
        :title="$t('Back_to_meeting')"
        :subtitle="toLocaleDateTimeString(meeting.start)"
        :parent="meeting"
        empty-message=""
      />
    </Sidebar>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="headline">{{ $t('Spread_documents') }}</h2>
          </v-card-title>
          <v-card-text>
            <p>{{ $t('Spread_documents_description') }}</p>
            <v-list two-line>
              <v-list-item class="px-1" @click="selectReadUsers(true)">
                <v-list-item-avatar class="rounded-0">
                  <v-icon color="green">mdi-check</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $tc('Members_read_document', countRead(true), { count: countRead(true) }) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $tc('Select_members', countRead(true), { count: countRead(true) }) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-1" @click="selectReadUsers(false)">
                <v-list-item-avatar class="rounded-0">
                  <v-icon color="red">mdi-close</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $tc('Members_unread_document', countRead(false), { count: countRead(false) }) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $tc('Select_members', countRead(false), { count: countRead(false) }) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <UserTable v-model="selected" :users="users" read-documents> </UserTable>
            <NotificationTextarea v-model="message" v-cmdenter="send" class="my-4" />
            <v-btn :disabled="!selected.length" :loading="loading" color="primary" raised @click.stop="send">
              {{ $t('Send') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </BaseLayout>
</template>

<script>
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import Committee from '@/store/models/committee'
import Meeting from '@/store/models/meeting'
import User from '@/store/models/user'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'MeetingReadDocuments',
  async initialAsyncData({ id }) {
    const meeting = await Meeting.findOrFetch(id)
    await Promise.all([Committee.findOrFetch(meeting.committee_id), User.readDocumentUsers(meeting)])
  },
  data() {
    return {
      selected: [],
      message: '',
      loading: false,
    }
  },
  computed: {
    meeting() {
      return Meeting.query().with('committee').find(this.$route.params.id)
    },
    breadcrumbs() {
      return [this.meeting.committee, this.meeting]
    },
    toolbarTitle() {
      return this.meeting.title
    },
    users() {
      return User.query().all()
    },
    notificationURL() {
      const notificationURLTemplate = '/committees/{committee}/meetings/{meeting}/notification/'
      return createLink(notificationURLTemplate, {
        committee: this.meeting.committee_id,
        meeting: this.meeting.id,
      })
    },
  },
  methods: {
    toLocaleDateTimeString,
    selectReadUsers(read) {
      this.selected = User.query().where('read_documents', read).all()
    },
    countRead(read) {
      return User.query().where('read_documents', read).count()
    },
    async send() {
      this.loading = true
      const data = {
        message: this.message,
        principals: this.selected.map((principal) => ({
          id: principal.id,
          type: 'user',
        })),
      }
      await api.post(this.notificationURL, data)
      this.loading = false
      this.reset()
      this.notifySuccess(this.$t('notification_sent'))
    },
    reset() {
      this.selected = []
      this.message = ''
    },
  },
}
</script>
