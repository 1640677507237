import { api } from '@/api'
import find from 'lodash/find'

function filterTags(tags, contentType, user) {
  return tags.filter((tag) => {
    const hasContentType = tag.content_types.includes(contentType)
    const isCommitteeGroupMember = tag.committee_group_members.includes(user.id)
    const isCommitteeMember = tag.committee_members.includes(user.id)
    const isTagger = tag.taggers.includes(user.id)
    return hasContentType && (isCommitteeGroupMember || isCommitteeMember || isTagger)
  })
}

const state = {
  tags: [],
}

const mutations = {
  setTags(store, tags) {
    store.tags = tags
  },
}

const actions = {
  async fetch({ commit }) {
    commit('setTags', (await api.get('/v1/tag')).data)
  },
}

const getters = {
  getTags: (store) => (contentType, user) => {
    return filterTags(store.tags, contentType, user)
  },
  getTagForSlug: (store) => (slug) => {
    return find(store.tags, { slug })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
