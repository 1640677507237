import { api } from '@/api'
import { createLink } from '@/api/helpers'

async function fetchByURL(dispatch, url) {
  const { data } = await api.get(url)
  dispatch('entities/note/create', { data }, { root: true })
}

const state = {
  selected: null,
  toolbarVisible: false,
  annotating: false,
}

const actions = {
  fetchAll({ dispatch }) {
    fetchByURL(dispatch, createLink('notes'))
  },
  fetchAllByCommittee({ dispatch }, { id }) {
    fetchByURL(dispatch, createLink('committees/{id}/notes', { id }))
  },
  select(context, { id }) {
    context.state.selected = id
  },
  toggleToolbarVisibility(context) {
    context.state.toolbarVisible = !context.state.toolbarVisible
  },
  setToolbarVisibility(context, { value }) {
    context.state.toolbarVisible = value
  },
  setAnnotating(context, { value }) {
    context.state.annotating = value
  },
}

const getters = {
  getSelected(s) {
    return s.selected
  },
  getToolbarVisibility(s) {
    return s.toolbarVisible
  },
  getAnnotating(s) {
    return s.annotating
  },
}

export default {
  state,
  actions,
  getters,
  namespaced: true,
}
