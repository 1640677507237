<template>
  <div v-if="meetingCopy.committee.can_import_meeting">
    <template v-if="editing">
      <v-textarea
        v-model="meetingCopy.description"
        data-testid="meeting-description-textarea"
        full-width
        auto-grow
        filled
        hide-details
        class="mb-2"
        :placeholder="$t('meeting_description_placeholder')"
      />
      <v-btn
        data-testid="meeting-description-update-button"
        small
        depressed
        class="mr-2"
        href="javascript:;"
        color="primary"
        @click="updateDescription"
        >{{ $t('update_description') }}</v-btn
      >
      <v-btn data-testid="meeting-description-reset-button" text small href="javascript:;" @click="cancel">{{
        $t('Cancel')
      }}</v-btn>
    </template>
    <template v-else-if="!editing && meeting.hasDescription">
      <p
        data-testid="meeting-description-text"
        class="meeting-description-text mb-0"
        v-html="meeting.linkifiedDescription"
      ></p>
      <v-btn
        class="ml-0"
        data-testid="meeting-description-edit-button"
        depressed
        color="primary"
        small
        href="javascript:;"
        @click="edit"
        >{{ $t('edit_description') }}</v-btn
      >
    </template>
    <template v-else>
      <em>
        {{ $t('missing_meeting_description') }}
        <a data-testid="create-new-description-link" href="javascript:;" @click="edit">{{
          $t('create_new_meeting_description')
        }}</a>
      </em>
    </template>
  </div>
  <RevealContent v-else :value="meetingCopy.description" />
</template>

<script>
import clone from 'lodash/cloneDeep'

export default {
  name: 'MeetingDescription',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      meetingCopy: clone(this.meeting),
    }
  },
  methods: {
    edit() {
      this.editing = true
    },
    cancel() {
      this.meetingCopy.description = this.meeting.description
      this.editing = false
    },
    async updateDescription() {
      try {
        await this.meetingCopy.update(['description'])
        this.editing = false
        this.notifySuccess(this.$t('meeting_description_success_message'))
      } catch (error) {
        this.notifyError(this.$t('meeting_description_error_message'))
      }
    },
  },
}
</script>
<style>
.meeting-description-text {
  white-space: pre-wrap;
}
</style>
