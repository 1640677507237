/**
 * This mixin is used for every document type:
 * 1. Protocol
 * 2. Attachment
 * 3. Meeting document
 * 4. Proposal
 *
 * This mixin allows the views using it to open a document without a specific version.
 * The mixin loads then redirects to the newest version when no version is set.
 * To use the mixin define a computed property named document which has to return
 * a query consisting of the document you want to serve.
 * Additionally a data named doumentType has to be provided where the mixin makes the
 * redirect to.
 */

const documentMixin = {
  computed: {
    document() {
      throw new Error(`Override 'document' computed property when using the document mixin`)
    },
    hasVersionId() {
      return Boolean(this.$route.query.version_id)
    },
    documentVersion() {
      return this.getDocumentVersion()
    },
  },
  methods: {
    getDocumentVersion(route = this.$route) {
      // If a specific version is requested, find it by its version_id.
      if (route.query.version_id) {
        return this.document.find(route.query.version_id)
      }
      // Without a version_id, get the first (and only) version filtered by the protocol id.
      return this.document.where('id', Number.parseInt(route.params.id)).first()
    },
    async redirectToVersion(to, from, next) {
      // Document type is needed for a proper redirect
      if (!this.documentType) {
        throw new Error(`Provide 'documentType' as data when using the document mixin`)
      }
      if (to.query.layer_id) {
        return next()
      }
      /**
       * Fetch the document version when not yet available.
       * This is the case when vue reuses the current component.
       * So when a user searches for a document with the same type the user has currently open.
       */
      const documentVersion = await this.document.model.fetch(Number.parseInt(to.params.id))
      /**
       * Redirect to the newest version of the document because we are currently not able
       * to index version properly in solr.
       */
      // eslint-disable-next-line camelcase
      const { id, version_id, selected_layer } = documentVersion
      return next({
        name: this.documentType,
        params: { id },
        query: { ...this.$route.query, version_id, layer_id: selected_layer },
      })
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.redirectToVersion(to, from, next)
    })
  },
  beforeRouteUpdate(...args) {
    this.redirectToVersion(...args)
  },
  head: {
    title() {
      return { inner: this.documentVersion.title }
    },
  },
}

export default documentMixin
