<template>
  <v-data-table
    :headers="headers"
    :items="agendaItems"
    :no-data-text="$t('no_data')"
    hide-default-footer
    :no-results-text="$t('No_entries_existing')"
  >
    <template v-slot:item.title="{ item }">
      <router-link :to="{ name: 'agendaitem', params: { id: item.id } }">
        {{ item.title }}
      </router-link>
    </template>
    <template v-slot:item.notes="{ item }">
      <template v-if="item.notes.length === 0"> - </template>
      <template v-else>
        <v-chip color="primary" text-color="white" small>{{ item.notes.length }}</v-chip>
      </template>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'AgendaItemTable',
  props: {
    headers: {
      type: Array,
      default: () => [
        { text: 'Nummer', align: 'left', value: 'number' },
        { text: 'Titel', align: 'left', value: 'title' },
        { text: 'Notizen', align: 'center', value: 'notes' },
      ],
    },
    agendaItems: {
      type: Array,
      required: true,
    },
  },
}
</script>
