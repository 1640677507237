// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-polyfill'

import { api, setBaseURL } from '@/api'
import joinPath from 'path.join'
import Vue from 'vue'
import { mapState } from 'vuex'
import { sync } from 'vuex-router-sync'
import Vuelidate from 'vuelidate'
import VueHead from 'vue-head'
import App from '@/App'
import createRouter from '@/router'
import rules from '@/rules'
import store from '@/store'
import head from '@/head'
import i18n from '@/i18n'
import '@/directives'
import FtwUI from '@4tw/ui'
import { initClient } from '@/mqtt'
import createVuetify from './plugins/vuetify'
import '@/global-components'
import '@/mixins'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

Vue.use(VueHead, head)
Vue.use(Vuelidate)
Vue.use(FtwUI)

Vue.config.productionTip = false
Vue.prototype.$rules = rules

async function bootstrap() {
  try {
    const { data } = await api.get('/api/v1/config')
    setBaseURL(joinPath('/', data.path_prefix, data.api_root))
    if (data.mqtt.available) {
      initClient(data, store)
    }
    return data
  } catch {
    throw new Error('Application bootstrap failed.')
  }
}

;(async () => {
  const config = await bootstrap()
  const router = createRouter(config)
  sync(store, router)

  const configMixin = Vue.mixin({
    computed: mapState({
      $config: (state) => state.config.config,
    }),
  })

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    store,
    i18n,
    vuetify: createVuetify(config),
    components: { App },
    mixins: [configMixin],
    beforeCreate() {
      this.$store.commit('config/setConfig', { config })
      this.$store.dispatch('tags/fetch')
    },
    template: '<App/>',
  })
})()
