<template>
  <TitledCard :title="$t('Note')" collapsable>
    <div class="d-flex flex-column">
      <CreateNote :committee-groups="committeeGroups" @create="create" />
      <template v-if="notes.length > 0">
        <template v-for="(note, index) in notes">
          <Note :key="note.listKey" :note="note" :committee-groups="committeeGroups" />
          <v-divider v-if="index + 1 < notes.length" :key="`divider-${note.listKey}`" />
        </template>
      </template>
      <p v-else class="mb-0 mt-2 text-xs-center grey--text">
        {{ $t('no_notes_existing') }}
      </p>
    </div>
  </TitledCard>
</template>

<script>
export default {
  name: 'NotesList',
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    addable: {
      type: Boolean,
      default: () => true,
    },
    committeeGroups: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    create(note) {
      this.$emit('create', note)
    },
  },
}
</script>
