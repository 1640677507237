<template>
  <div>
    <v-textarea
      v-model="input"
      :label="$t('add_notification_text_here')"
      hide-details
      v-bind="$attrs"
      class="notification-text-area mb-2"
      data-testid="notification-message"
    />
    <div class="d-flex">
      <v-spacer />
      <div v-if="$vuetify.breakpoint.smAndUp">
        <TextFragmentBox
          v-for="(fragBox, i) in fragmentBoxes"
          :key="i"
          class="mr-2"
          :label="fragBox.label"
          :content="fragBox.content"
          @inserted="insertTextBoxFragment"
        />
      </div>
      <v-menu v-if="fragments.length" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            outlined
            text
            color="primary"
            data-testid="notification-automatic-message"
            class="mr-0"
            v-on="on"
            >{{ $t('Automatic_Message') }}</v-btn
          >
        </template>
        <v-list data-testid="notification-automatic-messages-list">
          <v-list-item v-for="frag in fragments" :key="frag.name" @click="insertFragment(frag)">
            <v-list-item-title>{{ frag.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
function insertTextAt(textAreaNode, content) {
  if (document.selection) {
    textAreaNode.focus()
    const selection = document.selection.createRange()
    selection.text = this.content
  } else if (textAreaNode.selectionStart || textAreaNode.selectionStart === 0) {
    const startPos = textAreaNode.selectionStart
    const endPos = textAreaNode.selectionEnd
    // eslint-disable-next-line no-param-reassign
    textAreaNode.value =
      textAreaNode.value.substring(0, startPos) +
      content +
      textAreaNode.value.substring(endPos, textAreaNode.value.length)
  } else {
    // eslint-disable-next-line no-param-reassign
    textAreaNode.value += content
  }
  return textAreaNode.value
}

export default {
  name: 'NotificationTextarea',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: () => '',
    },
    fragments: {
      type: Array,
      default: () => [],
    },
    fragmentContext: {
      type: Object,
      default: () => ({}),
    },
    fragmentBoxes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    insertFragment(frag) {
      const textAreaNode = document.querySelector('.notification-text-area textarea')
      const content = frag.text(this.fragmentContext)
      this.input = insertTextAt(textAreaNode, content)
      textAreaNode.focus()
    },
    insertTextBoxFragment(content) {
      const textAreaNode = document.querySelector('.notification-text-area textarea')
      this.input = insertTextAt(textAreaNode, content)
      textAreaNode.focus()
    },
  },
}
</script>
