<template>
  <DividedList :items="items" :list-item="MeetingListItem" empty-message="" />
</template>

<script>
import MeetingListItem from '@/components/listitems/MeetingListItem'
import mixin from '@/components/lists/mixin'

export default {
  name: 'MeetingList',
  mixins: [mixin],
  data() {
    return { MeetingListItem }
  },
}
</script>
