<template>
  <DividedList :items="items" :empty-message="$t('no_committees_existing')" :list-item="CommitteeListItem" />
</template>

<script>
import CommitteeListItem from '@/components/listitems/CommitteeListItem'
import mixin from '@/components/lists/mixin'

export default {
  name: 'CommitteeList',
  mixins: [mixin],
  data() {
    return { CommitteeListItem }
  },
}
</script>
