<template>
  <p>redirecting&#8230;</p>
</template>

<script>
import Committee from '@/store/models/committee'

export default {
  name: 'Root',
  async beforeRouteEnter(to, from, next) {
    await Committee.fetchAll()
    const firstCommittee = Committee.query().first()
    if (firstCommittee) {
      next({ name: 'committee', params: { id: firstCommittee.id } })
    } else {
      next({ name: 'no-committee' })
    }
  },
}
</script>
