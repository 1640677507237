<template>
  <v-menu offset-y bottom nudge-bottom="5">
    <template #activator="{ on, attrs }">
      <v-tooltip bottom :disabled="isMenuActive">
        <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <v-btn
            :ripple="false"
            small
            :class="isMenuActive ? 'error' : 'grey'"
            class="mr-2 live-button"
            depressed
            v-bind="{ ...attrs, ...tooltipAttrs }"
            v-on="{ ...on, ...tooltipOn }"
          >
            <v-icon small color="white">mdi-play</v-icon>
          </v-btn>
        </template>
        <span>{{ tooltipMessage }}</span>
      </v-tooltip>
    </template>
    <v-list class="py-0">
      <v-list-item
        v-for="tagesordnungItem in activeTagesordnungItems"
        :key="tagesordnungItem.id"
        :to="tagesordnungItemLink(tagesordnungItem)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ tagesordnungItem.agenda_item.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ tagesordnungItem.meeting.title }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import isEmpty from 'lodash/isEmpty'
import { onMessage, offMessage } from '@/mqtt'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      activeTagesordnungItems: [],
    }
  },
  computed: {
    ...mapState('application', ['mqttConnected']),
    hasActiveTagesordnungItems() {
      return !isEmpty(this.activeTagesordnungItems)
    },
    isMenuActive() {
      return this.hasActiveTagesordnungItems && this.mqttConnected
    },
    tooltipMessage() {
      if (!this.hasActiveTagesordnungItems) {
        return this.$t('noActiveMeetings')
      }
      if (!this.mqttConnected) {
        return this.$t('missingMQTTConnection')
      }
      return ''
    },
  },
  mounted() {
    this.fetchActiveTagesordnungItems()
    onMessage('tagesordnung_item/is_active_changed', this.updateActiveTagesordnungItem)
  },
  beforeDestroy() {
    offMessage('tagesordnung_item/is_active_changed', this.updateActiveTagesordnungItem)
  },
  methods: {
    updateActiveTagesordnungItem({ active_tagesordnung_items: activeTagesordnungItems }) {
      this.activeTagesordnungItems = activeTagesordnungItems
    },
    async fetchActiveTagesordnungItems() {
      this.activeTagesordnungItems = (await api.get(createLink('tagesordnung_item/active'))).data
    },
    tagesordnungItemLink(tagesordnungItem) {
      return {
        name: 'agendaitem',
        params: { id: tagesordnungItem.agenda_item.id },
        query: { tagesordnungitem_id: tagesordnungItem.id },
      }
    },
  },
}
</script>
<style>
.live-button {
  min-width: 0 !important;
  padding: 0 6px !important;
  height: 26px !important;
}
</style>
