<template>
  <v-list two-line class="py-0">
    <draggable
      :value="sections"
      group="sections"
      handle=".handle_section"
      :animation="200"
      @update="handleSectionUpdate"
    >
      <v-card
        v-for="section in sections"
        :key="section.id"
        :data-testid="`section-card-${section.title}`"
        outlined
        class="mb-4 section"
      >
        <v-list-item v-if="editable">
          <v-list-item-avatar class="rounded-0">
            <v-icon class="handle_section">mdi-drag</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-text-field
              :ref="`section-${section.id}`"
              v-model="section.title"
              data-testid="section-name"
              filled
              class="ma-0"
              hide-details
              dense
              @input="handleTitleUpdate($event, section)"
            >
            </v-text-field>
          </v-list-item-content>
          <v-list-item-action v-if="editable">
            <v-btn icon data-testid="section-delete" @click.stop.prevent="handleDestroySection(section)">
              <v-icon color="error"> mdi-delete </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-card-title v-else>{{ section.title }}</v-card-title>
        <draggable
          :animation="200"
          :disabled="!editable"
          :value="section.documents"
          group="documents"
          data-testid="section-document-list"
          :class="{ empty: isEmpty(section.documents) && editable }"
          class="draggable-list"
          @update="handleDocumentUpdate"
          @add="handleAddDocumentToSection($event, section)"
        >
          <div v-for="(item, index) in section.documents" :key="item.listKey">
            <DeleteableDocumentListItem
              :item="item"
              :index="index"
              :count="items.length"
              :deleteable="editable"
              @destroy-item="$emit('destroy-item', { item })"
            />
          </div>
          <div v-if="isEmpty(section.documents) && editable" class="d-flex align-center justify-center h-full w-full">
            {{ $t('draggable_list_empty_message') }}
          </div>
        </draggable>
      </v-card>
    </draggable>
    <draggable
      :disabled="!editable"
      :value="items"
      group="documents"
      data-testid="documents-without-section"
      :class="{ empty: isEmpty(items) && editable }"
      class="draggable-list"
      :animation="200"
      @update="handleDocumentUpdate"
      @add="handleRemoveDocumentFromSection"
    >
      <div v-for="(item, index) in items" :key="item.listKey">
        <DeleteableDocumentListItem
          :item="item"
          :index="index"
          :count="items.length"
          :deleteable="editable"
          @destroy-item="$emit('destroy-item', { item })"
        />
      </div>
      <div v-if="isEmpty(items) && editable" class="d-flex align-center justify-center h-full w-full">
        {{ $t('draggable_list_empty_message') }}
      </div>
    </draggable>
    <p v-if="emptyMessageText && !items.length && !sections.length" class="mb-0 mt-2 text-xs-center grey--text">
      {{ emptyMessageText }}
    </p>
  </v-list>
</template>

<script>
import mixin from '@/components/lists/mixin'
import draggable from 'vuedraggable'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'

export default {
  name: 'SortableDocumentList',
  components: {
    draggable,
  },
  mixins: [mixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    sections: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fullName() {
      return this.firstName + ' ' + this.lastName
    },
  },
  methods: {
    isEmpty,
    handleTitleUpdate(event, section) {
      this.$emit('change-section-title', { title: event, section })
    },
    handleSectionUpdate({ newIndex, item: { _underlying_vm_: section } }) {
      this.$emit('reorder-section', { index: newIndex, section })
    },
    handleDocumentUpdate({ newIndex, item: { _underlying_vm_: document } }) {
      this.$emit('reorder-document', { index: newIndex, document })
    },
    handleAddDocumentToSection({ newIndex, item: { _underlying_vm_: document } }, section) {
      this.$emit('add-document-to-section', { index: newIndex, newIndex, document, section })
    },
    handleDestroySection(section) {
      this.$emit('destroy-section', { section })
    },
    handleRemoveDocumentFromSection({ newIndex, item: { _underlying_vm_: document } }) {
      this.$emit('remove-document-from-section', { index: newIndex, newIndex, document })
    },
    focusSection(section) {
      const sectionElement = first(this.$refs[`section-${section.id}`])
      if (!sectionElement) {
        return
      }
      sectionElement.$el.querySelector('input').select()
    },
  },
}
</script>
<style>
.draggable-list.empty {
  margin: 2px;
  height: 60px;
  border: 1px dashed var(--v-greydark-darken1);
}
</style>
