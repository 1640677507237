import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import * as VuexORM from '@vuex-orm/core'
import { VuexPersistence } from 'vuex-persist'
import VuexORMSearch from '@vuex-orm/plugin-search'
import config from '@/store/modules/config'
import AgendaItem from '@/store/models/agendaitem'
import Attachment from '@/store/models/attachment'
import Committee from '@/store/models/committee'
import Meeting from '@/store/models/meeting'
import Note from '@/store/models/note'
import note from '@/store/modules/note'
import PersonalDocument from '@/store/models/personaldocument'
import Proposal from '@/store/models/proposal'
import Protocol from '@/store/models/protocol'
import User from '@/store/models/user'
import Profile from '@/store/models/profile'
import Config from '@/store/models/config'
import Document from '@/store/models/document'
import MeetingDocument from '@/store/models/meetingdocument'
import Membership from '@/store/models/memberships'
import AnnotationLayer from '@/store/models/annotation_layer'
import Annotation from '@/store/models/annotation'
import TagesordnungItem from '@/store/models/tagesordnungitem'

import ApplicationModule from '@/store/modules/application'
import RisProposal from '@/store/modules/risproposal'
import RisSuggestion from '@/store/modules/rissuggestion'
import Notification from '@/store/modules/notification'
import AppSwitcher from '@/store/modules/appswitcher'
import Tags from '@/store/modules/tags'

Vue.use(Vuex)

VuexORM.use(VuexORMSearch)

export const database = new VuexORM.Database()

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['risproposal', 'rissuggestion'],
  reducer: (state) => ({
    // This only persists the risproposal module
    risproposal: state.risproposal,
    rissuggestion: state.rissuggestion,
    application: state.application,
  }),
})

database.register(Committee, {})
database.register(Meeting, {})
database.register(AgendaItem, {})
database.register(Attachment, {})
database.register(Note, note)
database.register(PersonalDocument, {})
database.register(Proposal, {})
database.register(Protocol, {})
database.register(User, {})
database.register(Profile, {})
database.register(Config, {})
database.register(Document, {})
database.register(MeetingDocument, {})
database.register(Membership, {})
database.register(AnnotationLayer, {})
database.register(Annotation, {})
database.register(TagesordnungItem, {})

export default new Store({
  plugins: [VuexORM.install(database), vuexLocal.plugin],
  modules: {
    config,
    application: ApplicationModule,
    risproposal: RisProposal,
    rissuggestion: RisSuggestion,
    notification: Notification,
    appswitcher: AppSwitcher,
    tags: Tags,
  },
})
