<template>
  <LinkedListItem :item="item" data-testid="attachment-list-item" :extra-query-params="extraQueryParams">
    <v-list-item-avatar class="rounded-0">
      <v-icon>{{ `${item.mimeTypeIcon}` }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="text-wrap d-flex align-center">
        {{ item.title }}
        <v-icon
          v-if="item.color_code"
          class="ml-2"
          :data-testid="`document_designation-icon-${item.title}`"
          :size="12"
          :color="item.color_code"
        >
          mdi-square-rounded
        </v-icon>
      </v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        {{ $t('last_update_on', { date: toLocaleDateTimeString(item.updated) }) }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <TagList :tags="item.tags" />
      </v-list-item-subtitle>
    </v-list-item-content>
    <ListActionIndicator :item="item" />
  </LinkedListItem>
</template>

<script>
import mixin from '@/components/listitems/mixin'
import { toLocaleDateTimeString } from '@/filters'

export default {
  name: 'AttachmentListItem',
  mixins: [mixin],
  computed: {
    extraQueryParams() {
      return { tagesordnungitem_id: this.$route.query.tagesordnungitem_id }
    },
  },
  methods: {
    toLocaleDateTimeString,
  },
}
</script>
