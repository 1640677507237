import Vue from 'vue'
import BaseModel from '@/store/models/base'
import Profile from '@/store/models/profile'
import i18n from '@/i18n/index'

let annotationComponent = null
function getAnnotationComponent() {
  if (!annotationComponent) {
    annotationComponent = Vue.extend(Vue.options.components.Annotation)
    annotationComponent.prototype.toJSON = () => {}
  }
  return annotationComponent
}

export default class Note extends BaseModel {
  static entity = 'note'

  static apiPath = 'notes'

  static fields() {
    return {
      id: this.attr(null),
      annotation: this.string(''),
      committee_groups: this.attr([]),
      created: this.string(''),
      public: this.boolean(false),
      object_id: this.number(0),
      content_type: this.string(''),
      author: this.attr(null),
      orphan: this.boolean(false),
      page_number: this.number(),
      position_x: this.number(0),
      position_y: this.number(0),
      node: this.attr(null),
      shared: this.boolean(false),
    }
  }

  async destroy() {
    this.detach()
    await super.destroy()
  }

  detach() {
    if (this.node) {
      this.node.$destroy()
      this.node.$el.remove()
    }
  }

  static get ordered() {
    return this.query().orderBy('created', 'desc')
  }

  get isOwner() {
    return Profile.find(this.author.id)
  }

  get annotatedObjectURL() {
    return {
      name: this.content_type,
      params: { id: this.object_id },
    }
  }

  /*
    Annotation specific methods
  */
  get x() {
    return this.position_x
  }

  get y() {
    return this.position_y
  }

  get pageNumber() {
    return this.page_number
  }

  get content() {
    return this.annotation
  }

  set x(v) {
    this.position_x = v
  }

  set y(v) {
    this.position_y = v
  }

  set pageNumber(v) {
    this.page_number = v
  }

  set content(v) {
    this.annotation = v
  }

  get selected() {
    return this.$store().getters['entities/note/getSelected'] === this.id
  }

  static deselect() {
    this.dispatch('select', { id: null })
  }

  select() {
    this.$store().dispatch('entities/note/select', { id: this.id })
  }

  set position(position) {
    this.x = position.x
    this.y = position.y
  }

  get position() {
    return {
      x: this.x,
      y: this.y,
    }
  }

  get pageNode() {
    return document.querySelector(`[data-page-number="${this.pageNumber}"]`)
  }

  get flipBounds() {
    const classes = []
    if (!this.node) {
      return classes
    }
    const nodeEl = this.node.$el
    const pageRect = this.pageNode.getBoundingClientRect()
    if (nodeEl.offsetLeft < 250) {
      classes.push('flip-x-left')
    } else if (pageRect.width - nodeEl.offsetLeft < 250) {
      classes.push('flip-x-right')
    }
    if (nodeEl.offsetTop < 200) {
      classes.push('flip-y')
    }
    return classes
  }

  render() {
    if (this.node) {
      this.node.$destroy()
      this.node.$el.remove()
    }
    const store = this.$store()
    const AnnotationVueComponent = getAnnotationComponent()
    const node = new AnnotationVueComponent({
      propsData: { id: this.id },
      store,
      i18n,
    }).$mount()
    this.pageNode.appendChild(node.$el)
    this.$update({ node })
  }
}
