<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-form ref="form" @submit.prevent="submit">
      <v-card>
        <v-card-title>
          <h3 class="headline">{{ membership.last_name }} {{ membership.first_name }}</h3>
        </v-card-title>
        <v-card-text>
          <slot name="person" />
          <v-select
            v-model="membership.roles"
            :rules="[$rules.required]"
            :items="roles"
            :label="$t('roles')"
            multiple
          />
          <v-text-field
            v-if="committee.members_can_see_all_meetings"
            v-model="membership.begin_date"
            :label="$t('begin_date')"
            type="date"
          />
          <v-text-field
            v-if="committee.members_can_see_all_meetings"
            v-model="membership.end_date"
            :label="$t('end_date')"
            type="date"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text @click="close">
            {{ $t('share_abort') }}
          </v-btn>
          <v-btn color="primary" :loading="loading" depressed type="submit">
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'MembershipEditDialog',
  props: {
    membership: {
      type: Object,
      required: true,
    },
    committee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      loading: false,
      dialog: false,
      roles: [
        { text: this.$t('access'), value: 'access' },
        { text: this.$t('member'), value: 'member' },
        { text: this.$t('responsible'), value: 'responsible' },
      ],
    }
  },
  methods: {
    close() {
      this.$refs.form.resetValidation()
      this.dialog = false
    },
    async submit() {
      this.loading = true
      try {
        if (this.membership.id) {
          await this.membership.update(['begin_date', 'end_date', 'roles'])
        } else {
          await this.membership.save()
          this.membership.user = null
        }
        this.notifySuccess(this.$t('share_success_message'))
      } catch (e) {
        this.notifyError(this.$t('share_error_message'))
        throw e
      } finally {
        this.close()
        this.loading = false
      }
    },
  },
}
</script>
