export default {
  computed: {
    stateVisibility() {
      if (this.note.orphan) {
        return 'red lighten-5'
      }
      if (this.isPublic) {
        return 'blue lighten-5'
      }

      return ''
    },
  },
}
