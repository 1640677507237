<template>
  <v-expansion-panels v-model="isOpen" class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-header :expand-icon="isOpen === 0 ? 'mdi-minus' : 'mdi-plus'">
        {{ $t('Add_note') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="d-flex flex-column">
          <CommitteeGroupSelect :note="note" :committee-groups="committeeGroups" />
          <v-textarea
            v-model="note.annotation"
            v-cmdenter="create"
            class="mb-1"
            :label="$t('add_note_here')"
            auto-grow
            :hint="$t('create_shortcut')"
            persistent-hint
          ></v-textarea>
          <div class="d-flex">
            <v-btn small depressed color="info" class="ml-0 mb-0" data-testid="submit-note" @click.native="create">
              {{ $t('Add') }}
            </v-btn>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { toLocaleDateTimeString } from '@/filters'
import Note from '@/store/models/note'
import NoteMixin from './mixin'

export default {
  name: 'Note',
  mixins: [NoteMixin],
  props: {
    committeeGroups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      note: new Note(),
      isOpen: 1,
    }
  },
  methods: {
    toLocaleDateTimeString,
    reset() {
      this.note.annotation = ''
      this.note.public = false
      this.isOpen = 1
      this.note.committee_groups = []
    },
    create() {
      this.$emit('create', this.note)
      this.reset()
    },
  },
}
</script>
<style scoped>
.note-content {
  white-space: pre-line;
}
</style>
