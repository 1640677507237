<template>
  <ftw-app-layout
    :app-name="appName"
    :permanent="!isMobile"
    :mini-variant.sync="miniVariant"
    :show-navigation.sync="showNavigation"
    :version="version"
    :style="{ 'background-color': $vuetify.theme.themes[theme].bodyBg }"
    show-help
    data-testid="app-layout"
    @help="openHelpDialog"
  >
    <!-- Page -->
    <v-main>
      <v-container v-if="breadcrumbs.length > 0 && $vuetify.breakpoint.mdAndUp" fluid class="pa-0 mb-0">
        <v-breadcrumbs :items="breadcrumbs" class="pb-0 pl-4 d-block">
          <template v-slot:item="{ item }">
            <router-link :to="item.url">{{ item.breadcrumbText }}</router-link>
          </template>
        </v-breadcrumbs>
      </v-container>
      <v-container fluid :class="{ 'pa-0': narrow, 'fill-height': fillContainerHeight }">
        <slot />
        <slot name="drawer-right" />
      </v-container>
    </v-main>

    <!-- Navigation -->
    <template v-if="!apps.length" #app-icon>
      <div class="app-icon-wrapper d-flex align-center">
        <v-icon :size="40" dark>$appIcon</v-icon>
      </div></template
    >
    <template v-else #app-icon>
      <ftw-app-switcher :current-app="currentApp" :apps="apps" @input="switchApp" />
    </template>
    <template v-if="isMobile || !miniVariant" #navigation-main>
      <slot name="drawer-left" />
    </template>

    <!-- AppBar -->
    <template #toolbar-main>
      <v-btn v-if="isMobile" icon class="mr-2" @click="showNavigation = true">
        <v-icon medium v-text="'mdi-menu'" />
      </v-btn>
      <LiveSearch v-if="showLiveSearch" class="mr-4" />
    </template>
    <template #toolbar-actions>
      <ActiveTagesordnungItems v-if="$config.mqtt.available" />
      <slot name="toolbar-actions" />
    </template>
    <template #usermenu>
      <UserMenu #usermenu />
    </template>
    <template #avatar>
      <v-avatar
        size="27"
        :color="matchColorToName(user.username)"
        class="user-menu-avatar"
        data-testid="user-menu-avatar"
      >
        <span class="black--text">{{ avatarChar }}</span>
      </v-avatar>
    </template>

    <!-- Other UI Component -->
    <Notification />
    <ShareDialog />
    <ShareAgendaItemDialog />
    <CookieLaw
      v-if="hasTracking"
      message="Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu."
      button-text="Einverstanden"
    />
    <HelpDialog ref="helpDialog" />
  </ftw-app-layout>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import Profile from '@/store/models/profile'
import { matchColorToName } from '@/ui-helpers'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Baselayout',
  components: {
    CookieLaw,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    narrow: {
      type: Boolean,
      default: () => false,
    },
    fillContainerHeight: {
      type: Boolean,
      default: () => false,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      miniVariant: false,
      showNavigation: false,
    }
  },
  computed: {
    ...mapState('appswitcher', ['apps']),
    ...mapGetters({
      currentApp: 'appswitcher/currentApp',
    }),
    appName() {
      return this.$t('appName')
    },
    hasTracking() {
      return !!this.$config.tracking_url
    },
    hasSolr() {
      return this.$config.has_solr
    },
    showLiveSearch() {
      return !this.hideSearch && this.hasSolr
    },
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    version() {
      return this.$store.state.application.version
    },
    username() {
      return Profile.query().first().username
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    user() {
      return Profile.query().first()
    },
    avatarChar() {
      return this.user && this.user.username[0].toUpperCase()
    },
  },
  watch: {
    '$vuetify.breakpoint.smAndDown'(isSmAndDown) {
      this.miniVariant = isSmAndDown
    },
  },
  methods: {
    matchColorToName,
    switchApp(v) {
      window.location.href = v.url
    },
    openHelpDialog() {
      this.$refs.helpDialog.open()
    },
  },
}
</script>

<style lang="scss">
.app-icon-wrapper {
  height: 50px;
}
.user-menu-avatar {
  border: 1px solid white;
}
</style>
