import Base from './base'

export default class Config extends Base {
  static entity = 'config'
  static apiPath = 'config'

  static fields() {
    return {
      version: this.string(''),
      apps_endpoint_url: this.string(''),
      app_name: this.string(''),
      root_url: this.string(''),
    }
  }
}
