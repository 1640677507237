<template>
  <div>
    <slot name="top" />
    <div
      v-for="currentTagesordnung in tagesordnungByDay"
      :key="currentTagesordnung.day"
      :data-testid="`tagesordnung-item-day-${currentTagesordnung.day}`"
      class="px-4"
    >
      <h3 class="mb-2" data-testid="tagesordnung-item-day">
        {{ toWeekday(currentTagesordnung.day) }} {{ toLocaleDateString(currentTagesordnung.day) }}
      </h3>
      <p class="show-line-breaks">{{ meeting.tagesordnung_slot_notes[currentTagesordnung.day] }}</p>
      <v-list data-testid="tagesordnung-item-list" two-line subheader>
        <v-list-item
          v-for="tagesordnungItem in currentTagesordnung.items"
          :key="tagesordnungItem.id"
          :to="agendaItemLink(tagesordnungItem)"
          color="primary"
          exact
          data-testid="tagesordnung-item"
        >
          <v-list-item-content>
            <v-list-item-title
              data-testid="tagesordnung-item-title"
              :class="{ 'font-weight-bold': tagesordnungItem.agenda_item.is_paragraph }"
            >
              {{ tagesordnungItem.agenda_item.title }}
            </v-list-item-title>
            <v-list-item-subtitle data-testid="tagesordnung-item-notes">
              {{ $t('tagesordnung_notes', { notes: tagesordnungItem.notes || '-' }) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle data-testid="tagesordnung-item-responsible">
              {{ $t('tagesordnung_responsible', { responsible: tagesordnungItem.agenda_item.responsible || '-' }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <TagesordnungLiveMeetingButton :meeting="meeting" :tagesordnung-item="tagesordnungItem" />
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>
<script>
import groupBy from 'lodash/groupBy'
import { toLocaleDateString, toWeekday } from '@/filters'
import TagesordnungItem from '@/store/models/tagesordnungitem'

import orderBy from 'lodash/orderBy'

export default {
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tagesordnung() {
      return TagesordnungItem.query().where('meeting_id', this.meeting.id).orderBy('order').all()
    },
    tagesordnungByDay() {
      const groups = groupBy(this.tagesordnung, 'day')
      const sortedGroups = orderBy(Object.entries(groups), [0], ['asc'])
      return sortedGroups.map(([day, items]) => ({ day, items }))
    },
  },
  watch: {
    meeting() {
      this.fetchTagesordnung()
    },
  },
  mounted() {
    this.fetchTagesordnung()
  },
  methods: {
    toLocaleDateString,
    toWeekday,
    fetchTagesordnung() {
      TagesordnungItem.fetchAllByMeeting(this.meeting)
    },
    agendaItemLink(tagesordnungItem) {
      if (tagesordnungItem.agenda_item.is_paragraph) {
        return
      }
      return {
        name: 'agendaitem',
        params: { id: tagesordnungItem.agenda_item.id },
        query: { tagesordnungitem_id: tagesordnungItem.id },
      }
    },
  },
}
</script>
