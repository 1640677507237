<template>
  <v-dialog v-model="isOpen" width="500" persistent>
    <template v-slot:activator="activatorProps">
      <slot v-bind="activatorProps" name="activator" />
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <form ref="form" class="container pa-0">
          <input
            ref="fileUpload"
            :accept="accept"
            type="file"
            class="file-upload-file-input"
            data-testid="file-upload-file-input"
            :value="inputValue"
            @change="fileUploadHandler"
          />
          <slot />
          <div class="d-flex">
            <v-text-field
              v-model="file.name"
              class="file-upload-file-path grow"
              :label="label"
              :error-messages="fileErrors"
              readonly
            ></v-text-field>
            <v-btn text class="file-upload-select-button" @drop.prevent="fileDropHandler" @click="openFileDialog">
              <span>{{ $t('Select') }}</span>
            </v-btn>
          </div>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="file-upload-discard-button" color="primary" text @click="close">{{ $t('Cancel') }}</v-btn>
        <v-btn
          color="primary"
          raised
          class="file-upload-upload-button"
          data-testid="file-upload-upload-button"
          :disabled="$v.file.$invalid"
          :loading="loading"
          @click="upload"
          >{{ $t('Create') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { api } from '@/api'
import i18n from '@/i18n/index'

export default {
  name: 'Upload',
  mixins: [validationMixin],
  props: {
    accept: {
      type: String,
      default: '*',
    },
    fileExtension: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: () => '',
    },
    url: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: 'Beim Upload ist ein Fehler aufgetreten.',
    },
  },
  data() {
    return {
      file: {},
      isOpen: false,
      inputValue: null,
      uploadErrorMessage: '',
      loading: false,
    }
  },
  computed: {
    fileErrors() {
      const errors = []
      if (!this.$v.file.$dirty) return errors
      if (!this.$v.file.validFileType) {
        errors.push(i18n.t('only_zip_error_msg', { extension: this.fileExtension }))
      }
      if (this.uploadErrorMessage) {
        errors.push(this.uploadErrorMessage)
      }
      return errors
    },
  },
  watch: {
    isOpen(curVal, newVal) {
      if (!curVal && newVal && this.file) {
        this.close()
      }
    },
  },
  validations: {
    file: {
      required,
      validFileType() {
        if (!this.fileExtension) {
          return true
        }

        return this.file && this.file.name.endsWith(`.${this.fileExtension}`)
      },
    },
  },
  created() {
    document.ondragover = (e) => {
      e.preventDefault()
    }
    document.ondrop = (e) => {
      e.preventDefault()
      this.fileSelected(e.dataTransfer.files)
    }
  },
  destroyed() {
    document.ondragover = () => {}
    document.ondrop = () => {}
  },
  methods: {
    async upload() {
      this.loading = true
      const v = this.$v
      const form = new FormData(this.$refs.form)
      form.append(this.field, this.file)
      try {
        const res = await api.post(this.url, form)
        this.$emit('uploaded', res)
        this.close()
        return res
      } catch (error) {
        const {
          response: { data },
        } = error
        this.uploadErrorMessage = data[this.field] || data.non_field_errors.join(', ') || this.errorMessage
        v.file.$touch()
        return error
      } finally {
        this.loading = false
      }
    },
    fileSelected(files) {
      if (files.length) {
        ;[this.file] = files
      }
      this.uploadErrorMessage = ''
      this.$v.file.$touch()
      this.$emit('fileAttached', this.file)
    },
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
      this.file = {}
      this.inputValue = null
      this.uploadErrorMessage = ''
      this.$v.file.$reset()
      this.$emit('canceled')
    },
    openFileDialog() {
      this.$refs.fileUpload.click()
    },
    fileUploadHandler(e) {
      this.fileSelected(e.target.files)
    },
  },
}
</script>
<style lang="scss" scoped>
.file-upload-select-button {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.file-upload-file-input {
  display: none;
}
</style>
