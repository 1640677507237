import BaseModel from '@/store/models/base'

export default class Document extends BaseModel {
  static entity = 'document'

  static apiPath = 'documents'

  static fields() {
    return {
      id: this.attr(null),
      title: this.string(''),
      file: this.string(''),
      committee_id: this.number(null),
      committee: this.belongsTo('committee', 'committee_id'),
      updated: this.attr(null),
    }
  }
}
