<template>
  <v-list-item-action
    v-if="needsIndicator"
    class="list-action-indicator d-flex flex-row fill-height align-start justify-end"
  >
    <v-tooltip v-if="isUnread" bottom style="margin-top: -1px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          :size="15"
          color="orange"
          :class="{ 'mr-1': isCommented }"
          class="unread-indicator"
          v-on="on"
        >
          mdi-record
        </v-icon>
      </template>
      <span>{{ $t('unread') }}</span>
    </v-tooltip>
    <v-tooltip v-if="isCommented" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" :size="15" color="blue" class="comment-indicator" v-on="on"> mdi-comment </v-icon>
      </template>
      <span>{{ $t('already_commented') }}</span>
    </v-tooltip>
  </v-list-item-action>
</template>

<script>
import isNull from 'lodash/isNull'

export default {
  name: 'ListActionIndicator',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isCommentedFunction: {
      type: Function,
      default: (item) => item.has_annotations,
    },
  },
  computed: {
    needsIndicator() {
      return this.isUnread || this.isCommented
    },
    isUnread() {
      return isNull(this.item.last_seen)
    },
    isCommented() {
      return this.isCommentedFunction(this.item)
    },
  },
}
</script>
<style>
.list-action-indicator {
  min-width: 40px;
}
</style>
