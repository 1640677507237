import omit from 'lodash/omit'

const dateTimeFilterMixin = {
  data() {
    return {
      beginDateMax: null,
      beginDateMin: null,
    }
  },
  watch: {
    beginDateMax(newMax) {
      if (!newMax && !this.beginDateMin) {
        this.$emit('update:filter', omit(this.filter, ['date']))
        return
      }
      this.$emit('update:filter', { ...this.filter, date: `${this.beginDateMin || '*'} TO ${newMax || '*'}` })
    },
    beginDateMin(newMin) {
      if (!newMin && !this.beginDateMax) {
        this.$emit('update:filter', omit(this.filter, ['date']))
        return
      }
      this.$emit('update:filter', { ...this.filter, date: `${newMin || '*'} TO ${this.beginDateMax || '*'}` })
    },
  },
}

export default dateTimeFilterMixin
