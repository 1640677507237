import Cookies from 'js-cookie'
import VueI18n from 'vue-i18n'
import Vue from 'vue'

const messages = {
  de: require('./locales/de.json'),
  en: require('./locales/en.json'),
  fr: require('./locales/fr.json'),
}

Vue.use(VueI18n)

// Note that the language names DON'T need to be translated.
export const supportedLanguages = {
  de: 'Deutsch',
  fr: 'Français',
  en: 'English',
}
const fallbackLanguage = 'de'
const languageCookieName = 'I18N_LANGUAGE'

/**
 * Returns the current user's language by using the language cookie
 * or - if not available - the browser's language.
 * If the language is not supported the fallback language is
 * returned.
 */
function userLanguage() {
  let lang = Cookies.get(languageCookieName) || window.navigator.language || window.navigator.userLanguage
  lang = lang.split('-')[0].toLowerCase()

  return Object.keys(supportedLanguages).includes(lang) ? lang : fallbackLanguage
}

const i18n = new VueI18n({
  locale: userLanguage(),
  fallbackLocale: fallbackLanguage,
  messages,
  silentTranslationWarn: true,
})

Cookies.set(languageCookieName, userLanguage(), { sameSite: 'strict' })

/**
 * Returns the current language without the country part.
 * `de-ch` will become `de`
 */
export function currentLanguage() {
  return i18n.locale.split('-')[0]
}

/**
 * Switches the language in the language cookie
 */
export function switchLanguage(lang) {
  // Set sameSite to 'strict' as we have no need to pass this cookie to 3rd party sites.
  Cookies.set(languageCookieName, lang, { sameSite: 'strict' })
  i18n.locale = lang
}

export default i18n
