<template>
  <div>
    <v-list data-testid="committee-groups-list">
      <v-list-item v-for="group in groups" :key="group.id" ripple>
        <v-list-item-content>
          <v-list-item-title v-text="group.title" />
          <v-list-item-subtitle>
            <template v-if="group.members.length || group.email_addresses.length">
              <span v-if="group.members.length" v-text="$tc('committee_group_members_count', group.members.length)" />
              <span v-if="group.members.length && group.email_addresses.length" class="mx-2" v-text="'|'" />
              <span
                v-if="group.email_addresses.length"
                v-text="$tc('committee_group_email_addresses_count', group.email_addresses.length)"
              />
            </template>
            <span v-else class="text--disabled" v-text="$t('committee_group_no_recipients')" />
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div class="d-flex">
            <v-btn icon data-testid="edit-committee-group" class="mr-4" @click="editCommitteeGroup(group)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon data-testid="delete-committee-group">
              <v-icon color="error" @click="deleteCommitteeGroup(group)">mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-btn
      text
      outlined
      color="primary"
      data-testid="add-committee-group"
      @click="addNewCommitteeGroup"
      v-text="$t('Add')"
    />
    <CommitteeGroupEditDialog ref="editDialog" :committee="committee" @success="fetchGroups" />
  </div>
</template>

<script>
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import get from 'lodash/get'

export default {
  name: 'CommitteeGroupEditList',
  props: {
    committee: { type: Object, required: true },
  },
  data() {
    return {
      fetching: true,
      groups: [],
    }
  },
  created() {
    this.fetchGroups()
  },
  methods: {
    async fetchGroups() {
      this.fetching = true
      try {
        const { data } = await api.get(createLink('committees/{id}/committee_groups', { id: this.committee.id }))
        this.groups = data
      } catch (e) {
        this.notifyError(this.$t('committee_group_fetch_error'))
      } finally {
        this.fetching = false
      }
    },
    async deleteCommitteeGroup(group) {
      const confirm = window.confirm(this.$t('committee_group_delete_confirmation'))
      if (confirm) {
        try {
          await api.delete(
            createLink('committees/{id}/committee_groups/{groupId}', { id: this.committee.id, groupId: group.id }),
          )
        } catch (error) {
          if (error.isAxiosError && get(error, 'response.status') === 403) {
            this.notifyError(error.response.data.detail)
          } else {
            this.notifyError(this.$t('committee_group_delete_error'))
          }
        } finally {
          await this.fetchGroups()
        }
      }
    },
    editCommitteeGroup(group) {
      this.$refs.editDialog.open(group)
    },
    addNewCommitteeGroup() {
      this.$refs.editDialog.open()
    },
  },
}
</script>

<style scoped lang="scss"></style>
