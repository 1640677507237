export default {
  state: {
    config: {},
  },
  mutations: {
    setConfig(state, { config, router }) {
      state.config = config
    },
  },
  namespaced: true,
}
