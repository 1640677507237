<template>
  <Sidebar data-testid="committees-sidebar">
    <DividedSidebarList v-if="ungroupedCommittees" :list-item="CommitteeListItem" :items="ungroupedCommittees" />

    <template v-else-if="sortedCommittees && sortedCommittees.length > 0">
      <template v-if="displayTabs">
        <v-tabs v-model="committeeTab" grow slider-color="primary" class="shrink">
          <v-tab v-t="'active'" class="text-uppercase" />
          <v-tab v-t="'inactive'" class="text-uppercase" />
        </v-tabs>
      </template>
      <v-tabs-items v-model="committeeTab">
        <v-tab-item :transition="false" :reverse-transition="false" class="committee-tab">
          <v-list class="py-0" data-testid="active-committees">
            <template v-for="(groupType, groupTypeTitle) in activeCommittees" class="committee-group">
              <v-subheader :key="`groupType-${groupTypeTitle}`" class="pt-8 pb-2 text-uppercase font-weight-regular">{{
                groupTypeTitle
              }}</v-subheader>
              <CommitteeListItem v-for="committee in groupType" :key="committee.id" :item="committee" />
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item :transition="false" :reverse-transition="false" class="committee-tab">
          <v-list class="py-0" data-testid="inactive-committees">
            <template v-for="(groupType, groupTypeTitle) in inactiveCommittees" class="committee-group">
              <v-subheader :key="`groupType-${groupTypeTitle}`" class="pt-8 pb-2 text-uppercase font-weight-regular">{{
                groupTypeTitle
              }}</v-subheader>
              <CommitteeListItem v-for="committee in groupType" :key="committee.id" :item="committee" />
            </template>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <p v-else class="mb-0 mt-2 text-xs-center grey--text">
      {{ $t('no_data') }}
    </p>
  </Sidebar>
</template>

<script>
import Committee from '@/store/models/committee'
import CommitteeListItem from '@/components/listitems/CommitteeListItem'
import { groupBy } from 'lodash'
import i18n from '@/i18n'

export default {
  name: 'CommitteesSidebar',
  data() {
    return {
      CommitteeListItem,
      committeeTab: 0,
    }
  },
  computed: {
    displayTabs() {
      return Object.keys(this.inactiveCommittees).length > 0
    },
    sortedCommittees() {
      return Committee.all()
        .filter((commitee) => !commitee.hidden)
        .sort((x, y) => {
          if (x.group_type_order !== y.group_type_order) {
            return x.group_type_order - y.group_type_order
          }
          if (x.group_type_title !== y.group_type_title) {
            // Perhaps localCompare should be by the principal group naming lang ('de') rather than
            // the user language?
            return x.group_type_title.localeCompare(y.group_type_title, this.$i18n.locale)
          }
          return x.title.localeCompare(y.title, this.$i18n.locale)
        })
    },
    ungroupedCommittees() {
      // i.e. committees with no committee type
      // This should only happen if SPV is in use - in which case ALL committees will be without a group type
      if (this.sortedCommittees.every((c) => c.group_type_title === '')) {
        return this.sortedCommittees
      } else {
        // signal the template to use groupedCommittees
        return null
      }
    },
    activeCommittees() {
      return this.groupCommittees(this.sortedCommittees.filter((c) => c.has_active_membership))
    },
    inactiveCommittees() {
      return this.groupCommittees(this.sortedCommittees.filter((c) => !c.has_active_membership))
    },
  },
  methods: {
    groupCommittees(sorted) {
      const grouped = groupBy(sorted, (c) => c.group_type_title)
      // The title looks silly, but when Active comes - it might look better
      if (grouped['']) {
        grouped[i18n.t('committees_with_no_type')] = grouped['']
        delete grouped['']
      }
      return grouped
    },
  },
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer::v-deep .v-navigation-drawer__content {
  overflow: unset !important;
}
.v-tabs-items::v-deep .v-window__container {
  height: 100%;
}
.v-tabs-items::v-deep + .spacer {
  flex-grow: 0 !important;
}

.committee-group {
  border-bottom: rgba(0, 0, 0, 0.12) 1px solid;

  &:first-child {
    border-top: rgba(0, 0, 0, 0.12) 1px solid;
  }

  .v-list__tile {
    padding-top: 6px;
    padding-bottom: 6px;

    & > div {
      // gives consistent width backgrounds on hover
      width: 100%;
    }
    .group-type-title {
      font-weight: bold;
    }
    .v-list__tile {
      padding-left: 0px;
    }
  }
}

.committee-tab {
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
}
.v-tabs-items {
  flex: 1 0 auto;
  height: 0px;
}
</style>
