<template>
  <div class="d-flex flex-column fill-height overflow-y-auto">
    <slot />
    <v-spacer />
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
}
</script>
