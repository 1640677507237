<template>
  <DividedList
    data-testid="attachment-list"
    :items="sortedItems"
    :empty-message="$t('no_attachments_exist')"
    :list-item="AttachmentListItem"
  >
    <slot slot="top" name="top" />
  </DividedList>
</template>

<script>
import AttachmentListItem from '@/components/listitems/AttachmentListItem'
import mixin from '@/components/lists/mixin'
import { localeSort } from '@/sorters'
import { filterLatestVersions } from '@/version_utils'

export default {
  name: 'AttachmentList',
  mixins: [mixin],
  data() {
    return { AttachmentListItem }
  },
  computed: {
    sortedItems() {
      const copy = [...this.items]
      if (copy.some((x) => x.sort_order)) {
        return filterLatestVersions(copy).sort((a, b) => a.sort_order - b.sort_order)
      }
      return localeSort(filterLatestVersions(copy), 'title')
    },
  },
}
</script>
