import set from 'lodash/set'
import Config from '@/store/models/config'
import { getMockedApps } from '@/ui-helpers'
import axios from 'axios'

const state = {
  apps: [],
  apps_endpoint_url: null,
  currentApp: null,
}

const mutations = {
  set(state, { path, value }) {
    set(state, path, value)
  },
}

const actions = {
  async setAppSwitcher(context) {
    const config = await Config.query().first()
    context.commit('set', { path: 'apps_endpoint_url', value: config.apps_endpoint_url })
    context.commit('set', { path: 'currentApp', value: config.app_name })
    if (process.env.NODE_ENV === 'development') {
      context.commit('set', { path: 'apps', value: getMockedApps() })
      return
    }
    try {
      const response = await axios.get(`${context.state.apps_endpoint_url}`, { withCredentials: true })
      context.commit('set', { path: 'apps', value: response.data })
    } catch (e) {
      const apps = []
      context.commit('set', { path: 'apps', value: apps })
    }
  },
}

const getters = {
  currentApp(s) {
    return s.apps.find((app) => app.url.includes(s.currentApp))
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
