const mimetypes = {
  ai: 'illustrator',
  asc: 'text',
  bmp: 'image',
  conf: 'text',
  csv: 'text',
  def: 'text',
  diff: 'text',
  doc: 'word',
  docm: 'word',
  docx: 'word',
  dot: 'word',
  dotm: 'word',
  dotx: 'word',
  eml: 'mail',
  eps: 'blank',
  gif: 'image',
  html: 'html',
  in: 'text',
  ind: 'indesign',
  indd: 'indesign',
  ini: 'text',
  jpg: 'image',
  jpeg: 'image',
  key: 'openoffice_impress',
  ksh: 'text',
  log: 'text',
  mmap: 'mindmanager',
  mp3: 'audio',
  mpp: 'msproject',
  mpt: 'msproject',
  mpv: 'msproject',
  mpx: 'msproject',
  msg: 'mail',
  numbers: 'openoffice_calc',
  odf: 'openoffice_math',
  odg: 'openoffice_draw',
  odm: 'openoffice_writer',
  odp: 'openoffice_impress',
  ods: 'openoffice_calc',
  odt: 'openoffice_writer',
  one: 'onenote',
  otg: 'openoffice_draw',
  oth: 'openoffice_writer',
  otp: 'openoffice_impress',
  ots: 'openoffice_calc',
  ott: 'openoffice_writer',
  pages: 'openoffice_writer',
  pdf: 'pdf',
  pjpeg: 'image',
  png: 'image',
  pot: 'powerpoint',
  potm: 'powerpoint',
  potx: 'powerpoint',
  ppa: 'powerpoint',
  ppam: 'powerpoint',
  pps: 'powerpoint',
  ppsm: 'powerpoint',
  ppsx: 'powerpoint',
  ppt: 'powerpoint',
  pptm: 'powerpoint',
  pptx: 'powerpoint',
  ppz: 'powerpoint',
  ps: 'blank',
  psd: 'photoshop',
  pub: 'openoffice_writer',
  pwz: 'powerpoint',
  rtf: 'openoffice_writer',
  stc: 'openoffice_calc',
  std: 'openoffice_draw',
  sti: 'openoffice_impress',
  stw: 'openoffice_writer',
  sxc: 'openoffice_calc',
  sxd: 'openoffice_draw',
  sxg: 'openoffice_writer',
  sxi: 'openoffice_impress',
  sxm: 'openoffice_math',
  sxw: 'openoffice_writer',
  txt: 'text',
  vdx: 'visio',
  vsd: 'visio',
  vsdm: 'visio',
  vsdx: 'visio',
  vsl: 'visio',
  vss: 'visio',
  vssm: 'visio',
  vssx: 'visio',
  vst: 'visio',
  vstm: 'visio',
  vstx: 'visio',
  vsw: 'visio',
  vsx: 'visio',
  vtx: 'visio',
  wav: 'audio',
  wiz: 'word',
  wmf: 'image',
  xla: 'excel',
  xlam: 'excel',
  xlb: 'excel',
  xlc: 'excel',
  xld: 'excel',
  xll: 'excel',
  xlm: 'excel',
  xls: 'excel',
  xlsm: 'excel',
  xlsx: 'excel',
  xlt: 'excel',
  xltm: 'excel',
  xltx: 'excel',
  xlw: 'excel',
  xml: 'html',
  zip: 'zip',
  blank: 'blank',
}

export default {
  ...mimetypes,
}

export function resolveIcon(extension) {
  if (!extension || !mimetypes[extension.split(/\./).pop()]) {
    return '$vuetify.icons.blank'
  }
  return `$vuetify.icons${extension}`
}
