<template>
  <BaseLayout>
    <CommitteesSidebar slot="drawer-left" />
    <v-row>
      <v-col cols="12">
        <TitledCard v-if="$config.enable_membership_management" :title="$t('membership_edit_title')">
          <p>
            {{ $t('membership_help_text') }}
          </p>
          <v-list data-testid="membership-list">
            <v-list-item v-for="membership in memberships" :key="membership.id" ripple>
              <v-list-item-content>
                <v-list-item-title> {{ membership.last_name }} {{ membership.first_name }} </v-list-item-title>
                <v-list-item-subtitle>
                  {{ membership.roles_display }}
                  <span v-if="committee.members_can_see_all_meetings && (membership.begin_date || membership.end_date)">
                    ({{ toLocaleDateString(membership.begin_date) }} - {{ toLocaleDateString(membership.end_date) }})
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex">
                  <MembershipEditDialog :membership="membership" :committee="committee">
                    <template #activator="{ on, attrs }">
                      <v-btn icon class="mr-4" v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                  </MembershipEditDialog>
                  <v-btn icon data-testid="delete-membership">
                    <v-icon color="error" @click="removeMembership(membership)">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <MembershipEditDialog :membership="newMembership" :committee="committee">
            <template #person>
              <v-autocomplete
                v-model="newMembership.user"
                :items="users"
                item-value="id"
                item-text="fullName"
                :label="$t('share_subtitle_name')"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.fullName"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </template>
            <template #activator="{ on, attrs }">
              <v-btn text outlined color="primary" v-bind="attrs" data-testid="add-membership" v-on="on">
                {{ $t('Add') }}
              </v-btn>
            </template>
          </MembershipEditDialog>
        </TitledCard>
        <v-card v-else>
          <v-alert class="alert-message" type="info" outlined>
            {{ $t('membership_management_is_disabled') }}
          </v-alert>
        </v-card>
      </v-col>
      <v-col cols="12">
        <TitledCard :title="$t('committee_group_edit_title')">
          <p v-text="$t('committee_group_help_text')" />
          <CommitteeGroupEditList ref="committeGroupEditList" :committee="committee" />
        </TitledCard>
      </v-col>
    </v-row>
  </BaseLayout>
</template>

<script>
import Membership from '@/store/models/memberships'
import Committee from '@/store/models/committee'
import User from '@/store/models/user'
import { toLocaleDateString } from '@/filters'
import TitledCard from '@/components/TitledCard.vue'

export default {
  name: 'CommitteeEdit',
  components: { TitledCard },
  async asyncData({ id }) {
    const committee = await Committee.findOrFetch(id)
    await Membership.fetchAllByModel(committee)
    await User.fetchAll()
  },
  data() {
    return {
      committeeId: Number.parseInt(this.$route.params.id, 10),
    }
  },
  computed: {
    memberships() {
      return Membership.query()
        .where('committee_id', this.committeeId)
        .orderBy('is_responsible', 'desc')
        .orderBy('last_name')
        .all()
    },
    committee() {
      return Committee.find(this.committeeId)
    },
    users() {
      return User.all()
    },
    newMembership() {
      return new Membership({ roles: ['member'], committee_id: this.committee.id })
    },
  },
  watch: {
    memberships() {
      this.$refs.committeGroupEditList.$refs.editDialog.fetchCommitteeUsers()
    },
  },
  methods: {
    toLocaleDateString,
    removeMembership(membership) {
      const confirm = window.confirm(this.$t('membership_delete_confirmation'))
      if (confirm) {
        membership.destroy()
      }
    },
  },
}
</script>
