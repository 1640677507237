<template>
  <v-data-table
    :headers="headers"
    :items="users"
    :value="value"
    item-key="id"
    hide-default-footer
    show-select
    :no-data-text="$t('No_entries_existing')"
    style="max-height: 320px; overflow: auto"
    data-testid="user-table"
    disable-pagination
    @input="input"
  >
    <template v-slot:item.read_documents="{ item }">
      <v-icon v-if="item.read_documents" color="green">mdi-check</v-icon>
      <v-icon v-else color="red">mdi-close</v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'UserTable',
  props: {
    users: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    readDocuments: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t('UserTable_header_name'), value: 'fullName' },
        { text: this.$t('UserTable_header_email'), value: 'email' },
        ...(this.readDocuments
          ? [{ text: this.$t('UserTable_header_spread_documents'), value: 'read_documents' }]
          : []),
      ],
    }
  },
  methods: {
    input(selected) {
      this.$emit('input', selected)
    },
  },
}
</script>
