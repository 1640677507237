function isCMDEnter(event) {
  return event.keyCode === 13 && (event.ctrlKey || event.metaKey)
}

function trackKey(e, func) {
  if (isCMDEnter(e)) {
    func()
  }
}

function inserted(el, binding) {
  const func = binding.value
  const textfield = el.querySelector('textarea')
  textfield.addEventListener('keydown', (e) => {
    trackKey(e, func)
  })
}

export default {
  name: 'cmdenter',
  inserted,
}
