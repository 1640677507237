<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-form :disabled="loading" @submit.prevent="submit">
      <v-card>
        <v-card-title>
          <h3 class="headline" v-text="instance ? instance.title : $t('committee_group_add_title')" />
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="formData.title"
            :label="$t('committee_group_field_title')"
            :error-messages="errors.title"
          />
          <v-autocomplete
            v-model="formData.members"
            multiple
            :label="$t('committee_group_field_members')"
            :error-messages="errors.members"
            :items="committeeUsers"
            item-value="id"
            item-text="displayName"
          >
            <template #item="{ attrs, on, item: user }">
              <v-list-item v-slot="{ active }" v-bind="attrs" v-on="on">
                <v-list-item-action>
                  <v-checkbox :input-value="active" :ripple="false" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <CommitteeGroupUserLabel :user="user" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template #selection="{ attrs, selected, item: user }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                :disabled="loading"
                small
                :color="!userIsInCommittee(user) ? 'warning' : undefined"
                @click:close="deselectUser(user)"
              >
                <CommitteeGroupUserLabel :user="user" />
              </v-chip>
            </template>
          </v-autocomplete>
          <v-alert
            v-if="hasSelectedMembersNotInCommittee"
            type="error"
            text
            dense
            class="mt-2 mb-8"
            data-testid="members-not-in-committee-hint"
          >
            <p class="mb-0" v-text="$t('committee_group_members_not_in_committee')" />
            <a
              data-testid="members-not-in-committee-clean"
              @click="deselectUsersNotInCommittee"
              v-text="$t('committee_group_members_not_in_committee_clean')"
            />
          </v-alert>
          <v-combobox
            ref="emailAddressesCombobox"
            v-model="formData.email_addresses"
            :label="$t('committee_group_field_email_addresses')"
            :error-messages="errors.email_addresses"
            multiple
            small-chips
            deletable-chips
          />
          <v-checkbox
            ref="shareAutomaticallyCheckbox"
            v-model="formData.share_automatically"
            :label="$t('committee_group_field_share_automatically')"
            :error-messages="errors.share_automatically"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text :disabled="loading" @click="close" v-text="$t('Cancel')" />
          <v-btn
            color="primary"
            type="submit"
            depressed
            :disabled="hasSelectedMembersNotInCommittee"
            :loading="loading"
            v-text="$t('Save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import isPlainObject from 'lodash/isPlainObject'
import without from 'lodash/without'
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import CommitteeGroupUserLabel from '@/components/committee/CommitteeGroupUserLabel'

export default {
  name: 'CommitteeGroupEditDialog',
  components: { CommitteeGroupUserLabel },
  props: {
    committee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      instance: null,
      loading: false,
      dialog: false,
      formData: {},
      errors: {},
      committeeUsers: [],
    }
  },
  computed: {
    defaultFormData() {
      return { title: '', members: [], email_addresses: [], share_automatically: false }
    },
    hasSelectedMembersNotInCommittee() {
      const committeeUserIds = this.committeeUsers.map((u) => u.id)
      return this.formData.members.some((id) => !committeeUserIds.includes(id))
    },
  },
  watch: {
    instance: {
      handler() {
        this.reset()
      },
      immediate: true,
    },
  },
  created() {
    this.fetchCommitteeUsers()
  },
  methods: {
    async fetchCommitteeUsers() {
      const { data } = await api.get(createLink('committees/{id}/users/', { id: this.committee.id }))
      this.committeeUsers = data
    },
    userIsInCommittee(user) {
      return this.committeeUsers.map((user) => user.id).includes(user.id)
    },
    deselectUser(user) {
      this.formData.members = without(this.formData.members, user.id)
    },
    deselectUsersNotInCommittee() {
      const committeeUserIds = this.committeeUsers.map((u) => u.id)
      this.formData.members = filter(this.formData.members, (memberId) => committeeUserIds.includes(memberId))
    },
    open(instance = null) {
      this.instance = instance
      this.dialog = true
    },
    reset() {
      this.formData = cloneDeep(this.instance || this.defaultFormData)
      this.errors = {}
    },
    close() {
      this.dialog = false
      this.reset()
    },
    onSuccess(response) {
      this.notifySuccess(this.$t('committee_group_save_success'))
      this.$emit('success', response)
      this.close()
    },
    onFormError(error) {
      const errors = error.response.data

      const emailAddressesErrors = get(errors, 'email_addresses')
      if (emailAddressesErrors && isPlainObject(emailAddressesErrors)) {
        errors.email_addresses = Object.entries(emailAddressesErrors)
          .map(([i, errors]) => {
            const email = this.formData.email_addresses[i]
            const error = flatten(errors)[0]
            return `"${email}": ${error}`
          })
          .join('\n')
      }
      this.errors = errors

      if (error.isAxiosError && get(error, 'response.status') === 403) {
        this.notifyError(error.response.data.detail)
      }
      if (error.response.data.non_field_errors) {
        this.notifyError(error.response.data.non_field_errors[0])
      }
    },
    onServerError() {
      this.notifyError(this.$t('committee_group_save_error'))
    },
    async submit() {
      this.$refs.emailAddressesCombobox.blur() // https://github.com/vuetifyjs/vuetify/issues/3424#issuecomment-687046943
      await this.$nextTick(async () => {
        this.loading = true
        try {
          let response
          if (this.instance) {
            const url = createLink('committees/{id}/committee_groups/{instanceId}/', {
              id: this.committee.id,
              instanceId: this.instance.id,
            })
            response = await api.patch(url, this.formData)
          } else {
            const url = createLink('committees/{id}/committee_groups/', {
              id: this.committee.id,
            })
            response = await api.post(url, this.formData)
          }
          this.onSuccess(response)
        } catch (error) {
          if (error.isAxiosError && error.response.status >= 400 && error.response.status < 500) {
            this.onFormError(error)
          } else {
            this.onServerError(error)
          }
        } finally {
          this.loading = false
        }
      })
    },
  },
}
</script>
