<template>
  <div class="flex-shrink-0">
    <DocumentNotificationDialog :document="document">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip v-bind="attrs" bottom v-on="on">
          <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <v-btn
              v-bind="{ ...tooltipAttrs, ...attrs }"
              icon
              data-testid="btn-send-notification"
              v-on="{ ...on, ...tooltipOn }"
            >
              <v-icon medium>mdi-bell</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('Send_notification') }}</span>
        </v-tooltip>
      </template>
    </DocumentNotificationDialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" icon v-on="on" @click="toggleVersionsSidebar">
          <v-icon medium>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('Show_versions') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PSPDFKITActions',
  props: {
    document: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    ...mapActions({
      toggleVersionsSidebar: 'application/toggleVersionsSidebar',
    }),
  },
}
</script>
