<template>
  <v-list data-testid="agenda-item-list" two-line subheader>
    <slot name="top" />
    <template v-if="items.length > 0">
      <template v-for="(item, index) in items">
        <AgendaItemListItem :ref="`agenda-item-${item.id}`" :key="item.listKey" :item="item" />
        <v-divider v-if="index !== items.length - 1" :key="`agendaitem-divider-${index}`" />
      </template>
    </template>
    <p v-if="emptyMessage && items.length === 0" class="mb-0 mt-2 text-xs-center grey--text">
      {{ emptyMessage }}
    </p>
    <slot name="bottom" />
  </v-list>
</template>

<script>
import mixin from '@/components/lists/mixin'

export default {
  name: 'AgendaItemList',
  mixins: [mixin],
  props: {
    scrollTo: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.scrollTo) {
      this.$refs[`agenda-item-${this.scrollTo.id}`][0].$el.scrollIntoView({ block: 'center' })
    }
  },
}
</script>
