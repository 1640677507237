<template>
  <v-row>
    <v-col>
      <div class="subtitle-2">Intranet</div>
      <div class="caption">
        <p class="ma-0">
          <a href="https://ris.zg.ch/rr" target="_blank">Ratsinformationssystem RIS</a>
        </p>
        <span class="label">E-Mail:</span>
        <p><a href="mailto:Betrieb.SKA@zg.ch">Betrieb.SKA@zg.ch</a></p>
      </div>
    </v-col>
    <v-col>
      <div class="subtitle-2">
        {{ $t('help_dialog_contact') }}
      </div>
      <div class="caption">
        <p class="ma-0">Staatskanzlei des Kantons Zug</p>
        <p class="ma-0">Team Betrieb</p>
        <p class="ma-0">Regierungsgebäude</p>
        <p class="ma-0">Seestrasse 2</p>
        <p class="ma-0">6301 Zug</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SupportZug',
}
</script>
