<template>
  <div>
    <AutocompleteFacet
      v-model="filter.document_type"
      :label="$t('documentTypeFacetLabel')"
      :items="facets.document_type"
    />
    <AutocompleteFacet v-model="filter.meeting" :label="$t('meetingFacetLabel')" :items="facets.meeting" />
  </div>
</template>

<script>
export default {
  name: 'DocumentFacets',
  props: {
    facets: {
      type: Object,
      default: () => ({}),
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
