<template>
  <v-snackbar
    :value="show"
    :color="severityColor"
    :data-severity="severity"
    data-testid="snackbar-container"
    max-width="600px"
    top
    app
    @input="close"
  >
    <div class="d-flex align-center">
      <v-icon class="mr-4" v-text="severityIcon" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div style="overflow-wrap: anywhere" data-testid="snackbar-message" v-html="message" />
    </div>
    <template v-slot:action>
      <v-btn icon @click.native="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { SEVERITY_COLOR_MAPPING, SEVERITY_ICON_MAPPING } from '@/store/modules/notification'

export default {
  computed: {
    ...mapState('notification', ['show', 'message', 'severity']),
    severityColor() {
      return this.$vuetify.theme.themes.light[SEVERITY_COLOR_MAPPING[this.severity]]
    },
    severityIcon() {
      return SEVERITY_ICON_MAPPING[this.severity]
    },
  },
  methods: {
    ...mapMutations({
      close: 'notification/close',
    }),
  },
}
</script>
