import { Model } from '@vuex-orm/core'
import joinPath from 'path.join'
import pick from 'lodash/pick'
import { api } from '@/api'
import { createLink } from '@/api/helpers'

export default class BaseModel extends Model {
  static async fetch(id) {
    const { data } = await api.get(createLink(joinPath(this.apiPath, String(id || ''))))
    await this.dispatch('insertOrUpdate', { data })
    return this.find(Number.parseInt(id, 10))
  }

  static async fetchAll() {
    const { data } = await api.get(createLink(this.apiPath))
    await this.dispatch('insertOrUpdate', { data })
    return this.all()
  }

  static findOrFetch(id) {
    const record = this.find(id)
    return record ? Promise.resolve(record) : this.fetch(id)
  }

  static async fetchAllByModel(model, action = 'insertOrUpdate') {
    const url = createLink('{modelURL}/{modelId}/{apiPath}', {
      modelURL: model.constructor.apiPath,
      modelId: model.id,
      apiPath: this.apiPath,
    })
    const { data } = await api.get(url)
    return this.dispatch(action, { data })
  }

  async save({ path = this.path } = {}) {
    const createdRecord = await api.post(createLink(path), this.$toJson())
    return this.$insert(createdRecord)
  }

  async destroy() {
    await api.delete(createLink(this.idPath))
    this.$delete({ where: this.id })
  }

  async update(updateKeys) {
    const patch = pick(this.$toJson(), updateKeys)
    const { data } = await api.patch(createLink(this.idPath), patch)
    this.$update({ where: this.id, data })
  }

  createNote(note) {
    const path = joinPath(this.idPath, 'notes')
    return note.save({ path })
  }

  get path() {
    return this.constructor.apiPath || this.constructor.entity
  }

  get idPath() {
    return joinPath(this.path, this.$id.toString())
  }

  get url() {
    return {
      name: this.constructor.entity,
      params: {
        id: this.id,
      },
    }
  }

  showURL(query = {}) {
    return {
      name: this.constructor.entity,
      params: {
        id: this.id,
      },
      query,
    }
  }

  get listKey() {
    return `${this.constructor.entity}-${this.id}`
  }
}
