<template>
  <DividedList
    data-testid="meeting-document-list"
    :items="items"
    :list-item="MeetingDocumentListItem"
    :empty-message="$t('no_documents_existing')"
  />
</template>

<script>
import MeetingDocumentListItem from '@/components/listitems/MeetingDocumentListItem'
import mixin from '@/components/lists/mixin'

export default {
  name: 'MeetingDocumentList',
  mixins: [mixin],
  data() {
    return { MeetingDocumentListItem }
  },
}
</script>
