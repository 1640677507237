<template>
  <div class="combobox-facet">
    <v-autocomplete
      v-bind="$attrs"
      :items="filteredItems"
      :no-data-text="!filteredItems.length ? $t('noValues') : $t('noFilterResults')"
      autocomplete="off"
      color="primary"
      item-text="label"
      item-value="value"
      clearable
      hide-details
      v-on="$listeners"
    >
      <template #item="{ item }">
        <v-list-item-content :class="{ 'bodylight--text': !item.count }">
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action-text :class="{ 'bodylight--text': !item.count }">
          {{ item.count }}
        </v-list-item-action-text>
      </template>
    </v-autocomplete>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: 'AutocompleteFacet',
  inheritAttrs: false,
  props: {
    items: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    filteredItems() {
      return Object.entries(this.items)
        .map(([key, value]) => ({
          label: value.label,
          value: key,
          count: value.count,
        }))
        .sort((a, b) => b.count - a.count)
    },
  },
}
</script>
