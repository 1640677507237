<template>
  <v-btn outlined text color="primary" @click="insert">{{ label }}</v-btn>
</template>
<script>
export default {
  name: 'TextFragmentBox',
  props: {
    label: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  methods: {
    insert() {
      this.$emit('inserted', this.content)
    },
  },
}
</script>
