<template>
  <div class="searchable-fuzzy-list" :class="{ scrollable: scrollable }">
    <v-text-field v-model="term" append-icon="mdi-magnify" :label="$t('Search')" single-line hide-details />
    <slot :items="items" />
  </div>
</template>

<script>
export default {
  name: 'FuzzyList',
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { term: null }
  },
  computed: {
    items: {
      get() {
        const items = this.query.search(this.term).get()
        this.$emit('update', items)
        return items
      },
    },
    scrollable() {
      return this.items.length > 5
    },
  },
  watch: {
    $route: {
      handler() {
        this.term = null
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss">
.searchable-fuzzy-list {
  .v-list {
    max-height: 72px * 5.75; // One list item (with two-line) has a height of 72px.
  }
  &.scrollable .v-list {
    overflow-y: auto;
    box-shadow: inset 0 -40px 40px -40px rgba(0, 0, 0, 0.1);
  }
}
</style>
