import BaseModel from '@/store/models/base'
import { onMessage } from '@/mqtt'
import { api } from '@/api'
import { createLink } from '@/api/helpers'

export default class TagesordnungItem extends BaseModel {
  static entity = 'tagesordnungitem'

  static apiPath = 'tagesordnung_item'

  static fields() {
    return {
      id: this.attr(null),
      day: this.attr(null),
      agenda_item: this.attr(null),
      notes: this.string(''),
      is_active: this.boolean(false),
      meeting_id: this.attr(null),
      order: this.attr(null),
    }
  }

  static async fetchAllByMeeting(meeting) {
    const { data: tagesordnungItems } = await api.get(createLink('meetings/{id}/tagesordnung_item', { id: meeting.id }))
    return this.dispatch('insertOrUpdate', {
      data: tagesordnungItems.map((tagesordnungItem) => ({ ...tagesordnungItem, meeting_id: meeting.id })),
    })
  }
}

onMessage('tagesordnung_item/is_active_changed', ({ meeting_id: meetingId, is_active: isActive, id }) => {
  TagesordnungItem.update({
    where: (tagesordnungItem) => {
      return tagesordnungItem.meeting_id === meetingId
    },
    data: { is_active: false },
  })
  TagesordnungItem.update({ where: id, data: { is_active: isActive } })
})
