import BaseModel from '@/store/models/base'

export default class Annotation extends BaseModel {
  static entity = 'annotation'

  static apiPath = 'annotation'

  static fields() {
    return {
      id: this.increment(),
      layer_id: this.attr(null),
      annotations: this.attr([]),
    }
  }
}
