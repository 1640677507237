import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import last from 'lodash/last'

/**
 * The store holds every version of every document. So for the sidebar only the latest version is relevant.
 * @param {Array} arr Set to filter the latest versions from
 */
export function filterLatestVersions(arr) {
  const groupedById = groupBy(arr, 'id')
  return Object.values(groupedById).reduce((accum, current) => [...accum, last(orderBy(current, 'version_id'))], [])
}
