<template>
  <DividedList
    :items="committeeGroups"
    :empty-message="$t('no_committee_groups_existing')"
    :list-item="CommitteeGroupListItem"
  />
</template>

<script>
import CommitteeGroupListItem from '@/components/listitems/CommitteeGroupListItem'
import mixin from '@/components/lists/mixin'

export default {
  name: 'CommitteeGroupList',
  mixins: [mixin],
  props: {
    committeeGroups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { CommitteeGroupListItem }
  },
}
</script>
