<template>
  <div class="d-inline">
    <span class="font-weight-bold" v-text="displayName" />
    <span class="ml-2 text--primary brackets-around">
      <span v-if="user.email" class="text--disabled" v-text="user.email" />
      <span v-else class="error--text font-weight-regular" v-text="$t('committee_group_field_members_no_email')" />
    </span>
    <span v-if="user.roles_display" class="ml-2 text--primary brackets-around" v-text="user.roles_display" />
  </div>
</template>

<script>
import compact from 'lodash/compact'
export default {
  name: 'CommitteeGroupUserLabel',
  props: {
    user: { type: Object, required: true },
  },
  computed: {
    displayName() {
      const fullName = compact([this.user.last_name, this.user.first_name]).join(' ')
      return fullName || this.user.username
    },
  },
}
</script>

<style lang="scss" scoped>
.brackets-around {
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
}
</style>
