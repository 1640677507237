<template>
  <v-row>
    <v-col>
      <div class="subtitle-2">
        {{ $t('help_dialog_contact') }}
      </div>
      <div class="caption">
        <p class="ma-0">4teamwork AG</p>
        <p class="ma-0">Dammweg 9</p>
        <p class="ma-0">3013 Bern</p>
        <p class="ma-0">
          <a href="https://www.4teamwork.ch" target="_blank">www.4teamwork.ch</a>
        </p>
      </div>
    </v-col>
    <v-col>
      <div class="subtitle-2">
        {{ $t('help_dialog_support') }}
      </div>
      <div class="caption">
        <p class="ma-0">
          <a href="tel:+41315110404">031 511 04 04</a>
        </p>
        <p class="ma-0">
          <a href="mailto:support@4teamwork.ch">support@4teamwork.ch</a>
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DefaultVue',
}
</script>
